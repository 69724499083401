import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Polygon} from "@react-google-maps/api";
import PropTypes from "prop-types";
import {latLngToPoint, pointToLatLng} from "../../controller/mapper";
import {ifFuncExec} from "../../controller/common";
import Constants from "../../controller/Constants";
import {events} from "../../controller/Constants";
import EventEmitter from "../../controller/EventEmitter";
import {spreadBounds} from "../../controller/useEffectCondition";
import {usePolygonPathState, usePredictionState} from "../../Hooks/common";

const {AREA_SELECTION} = Constants.mapStates;

const toFloats = ({lat, lng}) => ({lat: ifFuncExec(lat), lng: ifFuncExec(lng)});

function compare2Paths(path, pathArray) {
  const compareLocations = (location, location2) => location.lat === location2.lat && location.lng === location2.lng;
  return path.length === pathArray.length && path.every((location, i) => compareLocations(toFloats(location), toFloats(pathArray[i])));
}

function AreaSelectionPolygon({onLoad: onLoadProp, map}) {
  const {zoom, mapState} = useSelector(mapStateToProps);
  const center = map.getCenter();
  const lastCenter = useRef(center);
  const lastMapState = useRef(null);
  const bounds = map.getBounds();
  const isAreaSelection = mapState === AREA_SELECTION;
  const [polygonPath, setPolygonPath] = usePolygonPathState();
  const [predictionState, setPredictionState] = usePredictionState();

  function generateInitialPath() {
    const point = latLngToPoint(center, bounds, zoom, map);
    const size = 150;
    const topRight = {x: point.x + size, y: point.y - size};
    const topLeft = {x: point.x - size, y: point.y - size};
    const bottomRight = {x: point.x + size, y: point.y + size};
    const bottomLeft = {x: point.x - size, y: point.y + size};
    return [topRight, topLeft, bottomLeft, bottomRight].map((point) => {
      const latLng = pointToLatLng(point, bounds, zoom, map);
      const {lat, lng} = latLng;
      return {lat: lat(), lng: lng()};
    });
  }

  const options = {
    fillColor: isAreaSelection ? "rgba(0,0,0,0.6)" : "rgba(205,205,205,0.8)",
    strokeColor: isAreaSelection ? "rgba(0,0,0,1)" : "rgba(205,205,205,1)",
    zIndex: isAreaSelection ? 11 : 0,
  };
  //const [path, setPath] = useState([]);
  const boundsSpread = spreadBounds(bounds);

  useEffect(() => {
    const condition = boundsSpread && lastMapState.current === null && isAreaSelection;
    if (condition) {
      lastMapState.current = mapState;
      lastCenter.current = center;
      try {
        const initalPath = generateInitialPath();
        //setPath(initalPath);
        setPolygonPath(initalPath);
      } catch (error) {
        console.error(error);
      }
    } else if (boundsSpread) {
      lastMapState.current = mapState;
    }
    // eslint-disable-next-line
  }, [center.lat, center.lng, boundsSpread].map(ifFuncExec));

  useEffect(() => {
    const {DELETE_POLYGON, RESET_POLYGON} = events;
    const deletePolygonEventId = EventEmitter.subscribe(DELETE_POLYGON, (data) => {
      setPredictionState(false); // not prediction
      setTimeout(() => {
        setPolygonPath([]);
      }, 0);
    });
    const resetPolygonEventId = EventEmitter.subscribe(RESET_POLYGON, (data) => {
      const initalPath = generateInitialPath();
      setPolygonPath([]);
      setTimeout(() => {
        setPolygonPath(initalPath);
      }, 50);
    });
    return () => {
      EventEmitter.unsubscribe(DELETE_POLYGON, deletePolygonEventId);
      EventEmitter.unsubscribe(RESET_POLYGON, resetPolygonEventId);
    };
  }, []);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  function alertPolygonError(toAlert) {
    if (toAlert) {
      window.alert(
        `It seems there is an error that requires a refresh....
                please refresh the page and try to drag the polygon again`
      );
    }
  }

  const mouseDrag = useRef({x: NaN, y: NaN, sameAsLast: true});
  const onAreaChange = useCallback(
    (isDrag) => {
      if (polygonRef.current) {
        const pathArray = polygonRef.current.getPath().getArray();
        if (compare2Paths(polygonPath, pathArray)) {
          if (isDrag === true) alertPolygonError(!mouseDrag.current.sameAsLast && isAreaSelection);
          return;
        }
        setPolygonPath(pathArray);
      }
    },
    [polygonPath]
  );
  const handleDragEnd = useCallback(() => {
    const ev = window.event;
    let pageX = ev.x;
    let pageY = ev.y;
    const {x, y} = mouseDrag.current;
    mouseDrag.current = {x: pageX, y: pageY, sameAsLast: pageX === x && pageY === y};
    onAreaChange(true);
  }, [onAreaChange]);
  const handleDragStart = useCallback(() => {
    const ev = window.event;
    let pageX = ev.pageX;
    let pageY = ev.pageY;
    mouseDrag.current = {x: pageX, y: pageY, sameAsLast: false};
  }, []);
  const onLoad = React.useCallback((polygon) => {
    polygonRef.current = polygon;
    onLoadProp(polygon);
    const pathObject = polygon.getPath();
    const deleteNode = (mev) => {
      if (mev.vertex != null) {
        polygonRef.current.getPath().removeAt(mev.vertex);
        setPolygonPath(polygon.getPath().getArray());
      }
    };
    listenersRef.current.push(
      pathObject.addListener("set_at", onAreaChange),
      pathObject.addListener("insert_at", onAreaChange),
      pathObject.addListener("remove_at", onAreaChange),
      window.google.maps.event.addListener(polygonRef.current, "rightclick", deleteNode)
    );
  }, []);
  const onUnmount = React.useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    listenersRef.current = [];
    polygonRef.current = null;
  }, []);

  return (
    polygonPath.length > 0 && (
      <Polygon
        path={polygonPath}
        editable={isAreaSelection}
        draggable={isAreaSelection}
        onMouseUp={handleDragEnd}
        onDragEnd={handleDragEnd}
        onMouseDown={handleDragStart}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
      />
    )
  );
}

AreaSelectionPolygon.defaultProps = {
  onLoad: () => {},
};
AreaSelectionPolygon.propTypes = {
  onLoad: PropTypes.func,
  map: PropTypes.object.isRequired,
};

function mapStateToProps(reduxState) {
  return {
    zoom: reduxState.map.mapZoom,
    mapState: reduxState.map.mapState,
  };
}

export default AreaSelectionPolygon;
