import {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setProject as setReduxProject} from "../store/actionCreators/general";
import {setAsyncProject} from "../store/actionCreators/general";
import Api from "../controller/ApiManager/index";
import {usePrevious} from "./common";
import EventEmitter from "../controller/EventEmitter";
import {events} from "../controller/Constants";

function useProject() {
  const project = useSelector((state) => state.general.project);
  return project;
}

export function useSetProject() {
  const dispatch = useDispatch();
  const setProject = useCallback((project) => dispatch(setReduxProject(project)), [dispatch]);
  return setProject;
}

export function useSetAndUpdateProject() {
  const dispatch = useDispatch();
  const setProject = useCallback(async (project) => dispatch(await setAsyncProject(project)), [dispatch]);
  return setProject;
}

function useProjectByCurrentMapLayer(setFetching, setError) {
  const [project, setProject] = useProjectState();
  const previousProject = usePrevious(project);
  const {projectId} = useParams();
  useEffect(() => {
    if (previousProject?.currentMapLayer === project.currentMapLayer || !previousProject?._id || previousProject?._id !== project._id) {
      return;
    }
    setFetching(true);
    Api.Project.getProject(projectId, {binsPopulated: true})
      .then((projectRes) => {
        setProject(projectRes);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [project.currentMapLayer]);
}

function useFetchProjectById(setError, setFetched, fetchProject) {
  const {projectId} = useParams();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setError(null);
    setFetched(false);
    fetchProject().then(() => ({}));
  }, [projectId, user.isAuthenticated]);
}

export function useFetchProject() {
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const {projectId} = useParams();
  const user = useSelector((state) => state.user);
  const [project, setProject] = useProjectState();
  const previousProject = usePrevious(project);

  async function fetchProject() {
    let projectRes;
    try {
      if (!user.isAuthenticated) {
        return;
      }
      setFetching(true);
      projectRes = await Api.Project.getProject(projectId, {binsPopulated: true});
      if (!projectRes) return;
      setProject(projectRes);
      setFetched(true);
      setError(null);
    } catch (err) {
      console.error(err);
      setError(err);
      return;
    } finally {
      setFetching(false);
      setFetched(true);
    }
    return projectRes;
  }

  EventEmitter.subscribe(events.UPDATE_PROJECT, fetchProject);

  useFetchProjectById(setError, setFetched, fetchProject);

  useProjectByCurrentMapLayer(setFetching, setError);

  return {fetching, error, fetched};
}

export function useProjectParams() {
  const project = useProject();
  const {currentMapLayer, mapLayersParams} = project;
  if (!currentMapLayer) throw Error("missing currentMapLayer in project");

  const {projectParams} = mapLayersParams.find((layerParams) => layerParams.mapLayer === currentMapLayer);
  return projectParams;
}

export default useProject;

export function useProjectState() {
  return [useProject(), useSetProject()];
}

export function useProjectActions() {
  return {
    project: useProject(),
    setProject: useSetProject(),
    setAndUpdateProject: useSetAndUpdateProject(),
  };
}
