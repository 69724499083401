import {useCallback} from "react";
import {useProjectParams} from "./useProject";
import Constants from "../controller/Constants";
import {environmentFactor, timeOfDayFactor} from "../controller/Constants";
import {useUserEnvironment} from "./useUserEnvironment";
import {useMapLayerSwitchState} from "./common";
import {useTimeOfDay} from "./useTimeOfDay";

export function useCorrectionFactorCalculator() {
  const projectParams = useProjectParams();
  const userEnvironment = useUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const [mapLayerSwitchState] = useMapLayerSwitchState();
  const envFactor = mapLayerSwitchState.EM ? 0 : environmentFactor[userEnvironment.toUpperCase()];
  const timeFactor = mapLayerSwitchState.EM ? 0 : timeOfDayFactor[timeOfDay.toUpperCase()];
  const {antennaPolarization, devParams} = projectParams;
  const {systemGain} = devParams;
  const polarizationLoss = antennaPolarization === Constants.polarizationTypes[3] ? 3 : 0;
  const antennaGainTable = {
    TYPE_1X1: 9.0,
    TYPE_2X2: 9.0,
    TYPE_4X4: 12.0,
    TYPE_8X8: 15.5,
    TYPE_8X8_STEERED: 20.7,
    TYPE_16X16: 18.0,
    TYPE_32X32: 21.0,
    TYPE_64X64_STEERED: 24.0,
  };
  const callback = useCallback(
    // Should we group the dependents of useMapContentEffects together with this?
    ({antennaType, txPower, txLoss}) => {
      const correctionFactor = txPower - txLoss  - systemGain  + envFactor + timeFactor;
      return correctionFactor;
    },
    [systemGain, userEnvironment, timeOfDay, mapLayerSwitchState] // Take all deps to single place?
  );
  return callback;
}
