import React, {useCallback, useEffect, useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import {useTranslation} from "react-i18next";
import Collapse from "react-bootstrap/Collapse";

import {Container} from "../StyledComponents/cardCollapseContainer";

import styled from "styled-components";
import { ARGENTINA_PROVIDERS } from "../../controller/Constants";

const StylessButton = styled.button`
  &&,
  &&:active {
    /* margin-right: auto; */
    width: ${({isSingleItemInRow}) => (isSingleItemInRow ? "100%" : "33%")};
    white-space: nowrap;
    background: none;
    color: ${({active}) => (active ? "#f5cb42" : "inherit")};
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  &&:active {
    color: #f5cb42;
  }
  &&:disabled {
    cursor: inherit;
    &&:active {
      color: inherit;
    }
    .list-group-item {
      background-color: #e7eaed;
      // font-size: 140px

    }
  }
`;

const FlexedDiv = styled.div`
  display: flex;
  background-color: #ebe8e1;
  justify-content: center;
`;

const Title = styled.div`
  white-space: nowrap;
  margin: 0px;
`;

const StyledIcon = styled.div`
  padding: 0px 10px;
  font-size: 20px;
`;

const StyledCard = styled(Card)``;

function CardCollapse({title, values, isIcon}) {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowUp, setArrowUp] = useState(isOpen);
  const {t} = useTranslation();

  useEffect(() => {
    const timeId = setTimeout(() => {
      setArrowUp(isOpen);
    }, 400);
    return () => {
      clearTimeout(timeId);
    };
  }, [isOpen]);

  return (
    <Container>
      <StyledCard className={"map-layer-card smart-city"}>
        <Card.Header className={"card-header-sf"} onClick={() => setIsOpen(!isOpen)}>
          <div className={"title-layer"}>
            <Title>{t(title)}</Title>
          </div>
          <i className={`fa fa-angle-${!arrowUp ? "down" : "up"}`} aria-hidden="true" />
        </Card.Header>
        <div>
          <Collapse in={isOpen} className={"card-body-collapse"}>
            <FlexedDiv className={"card-body-content"}>
              {values.map(({value, label, active, onClick, iconLabel, disabled, isSingleItemInRow}) => (
                <StylessButton
                  disabled={disabled}
                  value={value}
                  active={active}
                  onClick={() => onClick(value)}
                  isSingleItemInRow={isSingleItemInRow}
                >
                  <ListGroup.Item>
                    {isIcon ? (
                      <StyledIcon>
                        <i className={`fa fa-${iconLabel}`} aria-hidden="true"></i>
                      </StyledIcon>
                    ) : (
                      label
                    )}
                  </ListGroup.Item>
                </StylessButton>
              ))}
            </FlexedDiv>
          </Collapse>
        </div>
      </StyledCard>
    </Container>
  );
}

export default CardCollapse;
