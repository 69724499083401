import Form from "react-bootstrap/Form";
import LocationInput from "../Common/Inputs/LocationInput";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const MapContainer = styled.div`
  height: 200px;
  margin-top: 10px;
`;

const DivInputsAside = styled.div`
  margin-top: 8px;
`;
const FormLabel = styled(Form.Label)`
  margin-top: 10px;
`;
export default function SiteInputs({site, onSiteChange, handleLocationChange, vertical, address}) {
  const {t} = useTranslation();

  const changeLocationParam = useCallback(
    (keyName, value) => {
      try {
        const {location} = site;
        const newLocation = {...location, [keyName]: Number(value)};
        onSiteChange({...site, location: newLocation});
        handleLocationChange(newLocation);
      } catch (e) {
        console.error(e);
      }
    },
    [site, onSiteChange, handleLocationChange]
  );

  const {location} = site;
  const LngInput = (
    <Form.Control
      type="number"
      placeholder="Enter Site Lat"
      value={location.lat}
      onChange={(ev) => {
        changeLocationParam("lat", ev.target.value);
      }}
    />
  );
  const LatInput = (
    <Form.Control
      type="number"
      placeholder="Enter Site Longitude"
      value={location.lng}
      onChange={(ev) => {
        changeLocationParam("lng", ev.target.value);
      }}
    />
  );
  const AddressInput = <Form.Control type="text" placeholder="Address Not Available..." value={address} readOnly={true} />;
  const MapInput = (
    <>
      <Form.Text>please drag the marker or right click to set the location:</Form.Text>
      <MapContainer>
        <LocationInput center={location} onLocationChange={handleLocationChange} />
      </MapContainer>
    </>
  );
  const SiteNumberInput = (
    <>
      {" "}
      <FormLabel column={false}>{t("Site_Number")}</FormLabel>
      <Form.Control
        type="number"
        placeholder="Enter Site Number"
        value={site.siteNumber}
        onChange={(ev) => onSiteChange({...site, siteNumber: ev.target.value})}
      />
    </>
  );
  const SiteNameInput = (
    <>
      {" "}
      <FormLabel column={false}>{t("Site_Name_Code")}</FormLabel>
      <Form.Control
        type="text"
        placeholder={t("Enter Site Name")}
        value={site.siteName}
        onChange={(ev) => onSiteChange({...site, siteName: ev.target.value})}
      />
    </>
  );
  const SiteAreaInput = (
    <>
      {" "}
      <FormLabel column={false}>{t("Site_Area")}</FormLabel>
      <Form.Control
        type="text"
        placeholder={t("Enter Site Area")}
        value={site.area}
        onChange={(ev) => onSiteChange({...site, area: ev.target.value})}
      />
    </>
  );
  const inputs = [LngInput, LatInput, AddressInput, SiteNumberInput, SiteNameInput, SiteAreaInput];
  if (!vertical) {
    return (
      <>
        <div className={"col-sm-12"}>
          {MapInput}
          <FormLabel column={false}>{t("Site_Location")}</FormLabel>
        </div>
        {inputs.map((inputComp, i) => (
          <div key={i} className={"col-sm-4"}>
            {inputComp}
          </div>
        ))}
      </>
    );
  } else {
    return (
      <>
        <div className={"col-sm-7"}>{MapInput}</div>
        <DivInputsAside className={"col-sm-5"}>
          <Form.Label column={false}>{t("Site_Location")}</Form.Label>
          {inputs.slice(0, 3)}
        </DivInputsAside>
        {/*{inputs.slice(3).map((inputComp, i) => <div key={i} className={'col-sm-4'}>{inputComp}</div>)}*/}
      </>
    );
  }
}
