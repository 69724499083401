import {findDOMNode} from "react-dom";
import urls from "../configuations/endpoints";

function sortSmartTypeString(a, b) {
  const orderList = ["TYPE_1X1", "TYPE_2X2", "TYPE_4X4", "TYPE_8X8", "TYPE_8X8_STEERED", "TYPE_16X16", "TYPE_32X32", "TYPE_64X64_STEERED"];
  const aNum = orderList.indexOf(a);
  const bNum = orderList.indexOf(b);
  return aNum - bNum;
}
const smartTypes = {
  TYPE_1X1: 1,
  TYPE_2X2: 2,
  TYPE_4X4: 4,
  TYPE_8X8: 8,
  TYPE_8X8_STEERED: 8,
  TYPE_16X16: 16,
  TYPE_32X32: 32,
  TYPE_64X64_STEERED: 64,
};

const Constants = {
  TRANSLATION_URL: urls.TRANSLATION_URL,
  AUTH_URL: urls.AUTH_URL,
  REST_URL: urls.REST_URL,
  //^^^^^^^^^prototypes for code completions^^^^^^^^^^
  //^^^^^^^^^will be override by urls^^^^^^^^^^
  ...urls,
  GOOGLE_MAPS_API_KEY: "AIzaSyDwaf7lCE4td-ieAI3BV2cfmJrJS_CHzV4", // "AIzaSyCcw1r0WeZoTGxZDNR8ryrhHf37okOpp_U",
  EMAIL_URL: "https://aut4pawf7k.execute-api.eu-west-1.amazonaws.com/dev/",
  DEFAULT_PROJECT_SITES_IMAGE: "/assets/img/antenna.png",
  DEFAULT_STATIC_ANTENNA_MARKER: "/assets/img/antenna.png",
  SITE_IMAGE_PLACEHOLDER: "https://via.placeholder.com/150/000000/FFFFFF",
  JWT_TOKEN_ID: "token_id",
  JWT_REFRESH_TOKEN: "refresh_token",
  JWT_ACCESS_TOKEN: "access_token",
  JWT_EMAIL: "user_email",
  LOGIN_SUFFIX_URL: "login",
  REGISTER_SUFFIX_URL: "register",
  REFRESH_TOKEN_SUFFIX_URL: "refreshToken",
  EARTH_RADIUS: 6372.797,
  LAT_LNG_TO_METERS: 6372.797 * (Math.PI / 180),
  METERS_TO_LAT_LNG: (1 / 6372.797) * (Math.PI / 180),
  STAGE: process.env.REACT_APP_STAGE,
  modals: {
    REGISTER: "REGISTER",
    LOGIN: "LOGIN",
    PARAMETERS: "PARAMETERS",
    ADD_PROJECT: "ADD_PROJECT",
    TABLE: "TABLE",
    PROJECT_LIST: "PROJECT_LIST",
    EDIT_PROJECT: "EDIT_PROJECT",
    EDIT_SITE: "EDIT_SITE",
    CHOSE_2_SITES: "CHOSE_2_SITES",
    CLUSTER: "CLUSTER",
    CLUSTER_ALERT: "CLUSTER_ALERT",
    DISPLAY_COVERAGE: "DISPLAY_COVERAGE",
    TECH_CONVERSION: "TECH_CONVERSION",
    CHOOSE_POLYGON_ALERT: "CHOOSE_POLYGON_ALERT",
  },
  mapTypes: {
    BEST_SERVER: "BEST_SERVER",
    C2I: "C2I",
  },
  mapStates: {
    SITES_COVERAGE: "SITES_COVERAGE",
    AREA_SELECTION: "AREA_SELECTION",
  },
  events: {
    UPDATE_PROJECT: "UPDATE_PROJECT",
    SET_CENTER: "SET_CENTER",
    CHOSE_2_SITES: "CHOSE_2_SITES",
    REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
    DELETE_POLYGON: "DELETE_POLYGON",
    RESET_POLYGON: "RESET_POLYGON",
    CENTER_CHANGED: "CENTER_CHANGED",
    REARRANGE_ALL_LAYERS: "REARRANGE_ALL_LAYERS",
    SET_LOCATION_STATS: "SET_LOCATION_STATS",
  },
  localStorage: {
    REFRESH_TOKEN: "refreshToken",
    ID_TOKEN: "smartCityIdToken",
    ACCESS_TOKEN: "accessToken",
  },
  errors: {
    NO_1X1_MODEL: "sector is missing vertical(default) antenna model",
    FUNCTION_DEPRECATED: "function is deprecated!",
    TRY_AGAIN: "refresh token has dispatched, please try again you last operation.",
    UNAUTHORIZED: "user not authorized",
    NO_PROJECT_ID: "no providerId provided!",
    NO_BINS: "No BinsPlacements Field in Sector.",
  },
  smartTypeStrings: [...Object.keys(smartTypes)].sort(sortSmartTypeString),
  antennaModelStrings: [...Object.keys(smartTypes)].sort(sortSmartTypeString),
  smartTypes,
  technologies: ["3G-UMTS-FDD", "4G-LTE-FDD", "5G-TDD"],
  frequencyBands: [3600, 2600, 2100, 1800],
  channelBWs: [20, 40, 60, 100],
  polarizationTypes: ["Vertical", "Horizontal", "Circular", "Dual Slant 45deg"],
  mapLayerValues: [
    "RSSI - Coverage Optimized",
    "RSSI - Capacity Optimized",
    "MCS - Coverage Optimized",
    "MCS - Capacity Optimized",
    "Bit Rate - Coverage Optimized",
    "Bit Rate - Capacity Optimized",
    "RSRP - Coverage Optimized",
    "RSRP - Capacity Optimized",
    "RSRQ - Coverage Optimized",
    "RSRQ - Capacity Optimized",
  ],
  mapLayers: {
    RSSI_COVERAGE: "RSSI - Coverage Optimized",
    RSSI_CAPACITY: "RSSI - Capacity Optimized",
    MCS_COVERAGE: "MCS - Coverage Optimized",
    MCS_CAPACITY: "MCS - Capacity Optimized",
    BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
    BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
    RSRP_COVERAGE: "RSRP - Coverage Optimized",
    RSRP_CAPACITY: "RSRP - Capacity Optimized",
    RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
    RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
  },
  userGroups: {
    MANAGER: "manager",
    ADMIN: "admin",
  },
  antennaConfigs: ["Model-A", "Model-B", "Steered"],
  siteModes: {predesign: "Predesign", postdesign: "Postdesign"},
  mapLayerSwitchState: {EM: "EM", samples: "samples", wifi: "wifi"},
  smartCityKFactor: 1120,
  smartCityShowSiteIcon: false,
  showStaticAntennas: false,
};
export default Constants;
export const modals = {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  PARAMETERS: "PARAMETERS",
  ADD_PROJECT: "ADD_PROJECT",
  TABLE: "TABLE",
  PROJECT_LIST: "PROJECT_LIST",
  EDIT_PROJECT: "EDIT_PROJECT",
  EDIT_SITE: "EDIT_SITE",
  CHOSE_2_SITES: "CHOSE_2_SITES",
  CLUSTER: "CLUSTER",
  CLUSTER_ALERT: "CLUSTER_ALERT",
  DISPLAY_COVERAGE: "DISPLAY_COVERAGE",
  TECH_CONVERSION: "TECH_CONVERSION",
  CHOOSE_POLYGON_ALERT: "CHOOSE_POLYGON_ALERT",
};
export const smartCityConstants = {
  SIGNAL_ALARM_THRESHOLD: 10,
};
export const events = {
  UPDATE_PROJECT: "UPDATE_PROJECT",
  SET_CENTER: "SET_CENTER",
  CHOSE_2_SITES: "CHOSE_2_SITES",
  REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
  DELETE_POLYGON: "DELETE_POLYGON",
  RESET_POLYGON: "RESET_POLYGON",
  CENTER_CHANGED: "CENTER_CHANGED",
  REARRANGE_ALL_LAYERS: "REARRANGE_ALL_LAYERS",
  SET_LOCATION_STATS: "SET_LOCATION_STATS",
};
export const errors = {
  NO_1X1_MODEL: "sector is missing vertical(default) antenna model",
  FUNCTION_DEPRECATED: "function is deprecated!",
  TRY_AGAIN: "refresh token has dispatched, please try again you last operation.",
  UNAUTHORIZED: "user not authorized",
  NO_PROJECT_ID: "no providerId provided!",
  NO_BINS: "No BinsPlacements Field in Sector.",
};
export const mapLayerValues = [
  "RSSI - Coverage Optimized",
  "RSSI - Capacity Optimized",
  "MCS - Coverage Optimized",
  "MCS - Capacity Optimized",
  "Bit Rate - Coverage Optimized",
  "Bit Rate - Capacity Optimized",
  "RSRP - Coverage Optimized",
  "RSRP - Capacity Optimized",
  "RSRQ - Coverage Optimized",
  "RSRQ - Capacity Optimized",
];
export const mapLayers = {
  RSSI_COVERAGE: "RSSI - Coverage Optimized",
  RSSI_CAPACITY: "RSSI - Capacity Optimized",
  MCS_COVERAGE: "MCS - Coverage Optimized",
  MCS_CAPACITY: "MCS - Capacity Optimized",
  BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
  BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
  RSRP_COVERAGE: "RSRP - Coverage Optimized",
  RSRP_CAPACITY: "RSRP - Capacity Optimized",
  RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
  RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
};

export const PROVIDERS = ["Cellcom", "Pelephone", "PHI"]; // smart city
export const ARGENTINA_PROVIDERS = ["PERSONAL", "MOVISTAR", "CLARO"];  
export const ARGENTINA = "argentina"

export const TECHNOLOGYS = ["3G", "4G", "5G"]; // smart city

export const environmentFactor = {
  // smart city - CHANGE NAMES!
  MEN: 6,
  CAR: 0,
  HOME: -14,
};

export const userEnvironments = {
  // smart city - CHANGE NAMES!
  MEN: "Men",
  CAR: "Car",
  HOME: "Home",
};

// export const techUsageCategoriesThresholds = {
//   // smart city
//   PHONE_CALL: -80,
//   VIDEO_CALL: -80,
//   TEXT_MESSAGE: -80,
//   STREAMING: -65,
//   WEB_BROWSING: -70,
// };
export const techUsageCategoriesThresholds = {
  // smart city
  "4G": {
    Email: -108.8,
    "Voice Call": -105.1,
    "Email with attachment": -102.8,
    "Web Browsing - Webinar": -101.8,
    "Video Streaming HD - Zoom": -99.1,
    "Video Streaming FHD - Youtube": -93.8,
    "Video Streaming 4K - Youtube": -90.2,
    "Autonomous Vehicles": -85.7,
    "Autonomous Drones": -84.4,
  },
  "5G": {
    Email: -108.8,
    "Voice Call": -105.1,
    "Email with attachment": -102.8,
    "Web Browsing - Webinar": -101.8,
    "Video Streaming HD - Zoom": -99.1,
    "Video Streaming FHD - Youtube": -93.8,
    "Video Streaming 4K - Youtube": -90.2,
    "Autonomous Vehicles": -85.7,
    "Autonomous Drones": -84.4,
  },
  "3G": {
    Email: -101,
    "Voice Call": -100,
    "Email with attachment": -87,
    "Web Browsing - Webinar": -84,
  },
};

export const techUsageCategories = {
  // smart city
  PHONE_CALL: "Phone call",
  VIDEO_CALL: "Video call",
  TEXT_MESSAGE: "Text message",
  STREAMING: "Streaming",
  WEB_BROWSING: "Web browsing",
};

export const timeOfDay = {
  // smart city
  MORNING: "Morning",
  AFTERNOON: "Afternoon",
  EVENING: "Evening",
};
export const defaultTechnologiesCoverage = {
  // smart city
  Email: 0,
  "Voice Call": 0,
  "Email with attachment": 0,
  "Web Browsing - Webinar": 0,
  "Video Streaming HD - Zoom": 0,
  "Video Streaming FHD - Youtube": 0,
  "Video Streaming 4K - Youtube": 0,
  "Autonomous Vehicles": 0,
  "Autonomous Drones": 0,
};

export const timeOfDayFactor = {
  // smart city - CHANGE NAMES!
  MORNING: 3,
  AFTERNOON: 0,
  EVENING: -3,
};

function between(x, max, min) {
  // Should be refactored ^^^^^^
  return x >= min && x <= max;
}
export const smartCity = {
  getColor: (mapLayer, rssi) => {
    if (rssi > this.labelsColorsInfo[mapLayer].maxSignal) return "#fc03ec";
    if (rssi < this.labelsColorsInfo[mapLayer].minSignal) return "#ffffff";
    const color = this.labelsColorsInfo[mapLayer].find(({range}) => between(rssi, range.max, range.min)).color;
    return color;
  },
  // getMaxSignal: (mapLayer) => Math.max(this.labelsColorsInfo[mapLayer].values.map(value => value.range.max)),
  // getMinSignal: (mapLayer) => Math.min(this.labelsColorsInfo[mapLayer].values.map(value => value.range.min)),
  labelsColorsInfo: {
    signalMapLayer: [],
    EMMapLayer: {
      // maxSignal:  smartCity.getMaxSignal("EMMapLayer"),
      // minSignal: smartCity.getMinSignal("EMMapLayer"),
      values: [
        {
          rssiValue: -20,
          label: " Very High EM City",
          color: "#9e062a", //4287f5 blue//
          range: {
            max: 0.01,
            min: 0.0004,
          },
        },
        {
          rssiValue: -60,
          label: " High EM City",
          color: "#ff0800",
          range: {
            max: 4e-6,
            min: 4e-7,
          },
        },
        {
          rssiValue: -75,
          label: " Med. EM City",
          color: "#ffc019",
          range: {
            max: 4e-7,
            min: 4e-8,
          },
        },
        {
          rssiValue: -90,
          label: " Moderate EM City",
          color: "#21ea00", //4287f5 blue//
          range: {
            max: 4e-8,
            min: 4e-9,
          },
        },
        {
          rssiValue: -105,
          label: " Low EM City",
          color: "#b3f266",
          range: {
            max: 4e-9,
            min: 4e-10,
          },
        },
        {
          rssiValue: -120,
          label: " Ultra Low EM City",
          color: "#ffff",
          range: {
            max: 4e-10,
            min: 4e-11,
          },
        },
      ],
    },
  },
};
