import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import Backend from "i18next-xhr-backend";
import Constants from "./controller/Constants";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
let backendOptions = {
  loadPath: Constants.TRANSLATION_URL,
  crossDomain: true,
  withCredentials: true,
  ajax: function (url, options, callback, data) {
    let urlFinal = new URL(url);
    let params = {...options.queryStringParams};
    Object.keys(params).forEach((key) => urlFinal.searchParams.append(key, params[key]));
    fetch(urlFinal)
      .then((result) => result.json())
      .then((translations) => {
        let dataObj = {};
        if (!translations) {
          console.error("no Translations response from backend! ");
          callback(null, {status: 502});
        }
        translations.forEach((trans) => {
          if (trans.lang === params.lang) dataObj[trans.textCode] = trans.translation;
        });
        callback(JSON.stringify(dataObj), {status: 200});
      })
      .catch((err) => {
        callback(null, {status: 502});
      });
  },
  queryStringParams: {
    system: "cover",
    lang: "en",
  },
};
const I18n = i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // fallbackLng: 'en',
    debug: false,
    backend: backendOptions,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: "en",
  });
I18n.then((t) => {});
export default i18n;
