import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import {getColor, labelsColorsInfo, colorMapper, getLayerType, getRssiLevels, mapBitRateMult} from "../../controller/mapper";
import {sortSmartTypeString} from "../../controller/dataOperations";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useProjectParams} from "../../Hooks/useProject";
import Constants, {mapLayers} from "../../controller/Constants";
import Validator from "../../controller/Validator";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors";
import {isLight} from "../../controller/common";
import {useMapLayerSwitchState, useSelectedTechnology} from "../../Hooks/common";

const ColoredListItem = styled(ListGroup.Item)`
  color: ${(props) => (isLight(props.backcolor) ? "black" : "white")};
  font-weight: bold;
  //text-align: center;
  //text-shadow: 0 0 2px rgba(0,0,0,1), 1px 1px 0 rgba(0,0,0,0.7), -1px -1px 0 rgba(0,0,0,0.2) ;
  background-color: ${(props) => (props.backcolor ? props.backcolor + " !important" : "red")};
  &.list-group-item {
    && {
      border-radius: 0;
    }
    padding: 2px 4px;
    font-size: 12px;
  }
`;

const StyledModal = styled(Modal.Dialog)`
  &.modal-dialog {
    @media (min-width: 576px) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 0;
    border: none; //${({theme}) => theme.palette.primary.main};
    box-shadow: ${({theme}) => theme.shadows.map.control};
    .list-group-colors {
      border-radius: 0;
      min-width: 100px;
    }
  }
  .modal-header {
    padding: 7px 16px;
    background-color: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.common.white};
    border-radius: 0;
    .h4.modal-title {
      font-size: 15px;
    }
  }
`;
const ControlContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin: 0;
`;

const smartCityLabels = {
  ////// זה משמש לצבעים למטה ובנוסף כדאי לשים את כל המתשנים הקשוארים תחת אותו אובייקט בקבועים
  signal: [
    {
      rssiValue: -20, //?
      label: " High Coverage",
      color: "#00b14f",
    },
    {
      rssiValue: -60, //?
      label: " Good Coverage",
      color: "#92d050",
    },
    {
      rssiValue: -75, //?
      label: " Moderate. Coverage",
      color: "#f6eb14",
    },
    {
      rssiValue: -90, //?
      label: " Low Coverage",
      color: "#fec00f",
    },
    {
      rssiValue: -105, //?
      label: " Poor Coverage",
      color: "#ed2024",
    },
  ],

  EM: [],
};

const {RSRP_COVERAGE, RSRP_CAPACITY, RSRQ_CAPACITY, RSRQ_COVERAGE} = mapLayers;

function getColorLabels(mapType, mapLayer, rssiLevels, t, selectedSmartType, channelBW, mapLayerSwitchState, selectedTechnology) {
  let valueName = "RSSI";
  if (mapType === Constants.mapTypes.C2I) {
    valueName = "c/i";
  }
  if ([RSRP_COVERAGE, RSRP_CAPACITY].includes(mapLayer)) valueName = "RSRP";
  if ([RSRQ_COVERAGE, RSRQ_CAPACITY].includes(mapLayer)) valueName = "RSRQ";
  const layerType = getLayerType(mapLayer);
  const signalGenerator = (rssiValue, valueName, i, array) => {
    if (rssiValue !== Infinity) return ` ${i < array.length - 1 ? `${array[i + 1]} < ` : ""}${valueName} < ${rssiValue}`;
    return `${array[1]} < ${valueName}`;
  };
  const labelGenerator = {
    "": () => t("loading_cm "),
    RSSI: signalGenerator,
    MCS: (rssiValue, valueName, i, array) =>
      t(["No_Coverage_Mcs2", "QPSK_0.1", "QPSK_0.5", "16QAM_0.5", "64QAM_0.5", "64QAM_0.9"][array.length - i - 1].replace(".", "_")),
    Bit_Rate: (rssiValue, valueName, i, array) => {
      let stringsList =
        channelBW === 20
          ? ["No_Coverage_Br", "2.3_Mbps", "10_Mbps", "18.6_Mbps", "29.4_Mbps", "54_Mbps"]
          : ["No_Coverage_Br", "6.8_Mbps", "30_Mbps", "55.8_Mbps", "88.3_Mbps", "162_Mbps"];
      const bitRateMult = mapBitRateMult(selectedSmartType);
      if (mapLayer === mapLayers.BIT_RATE_CAPACITY && selectedSmartType) {
        stringsList = stringsList.map((label) => {
          const num = label.split("_")[0];
          if (!isNaN(num)) return `${(Number.parseFloat(num) * bitRateMult).toFixed(1)}_Mbps`;
          return label;
        });
      }
      return t(stringsList[array.length - i - 1].replace("_", " "));
    },
    RSRP: signalGenerator,
    RSRQ: signalGenerator,
  };

  return rssiLevels.map((rssiValue, i, array) => ({
    rssiValue,
    label: labelGenerator[layerType](rssiValue, valueName, i, array),
    color: colorMapper(rssiValue, rssiLevels.slice(1), mapLayerSwitchState, selectedTechnology),
  }));
}

function ColorMapping({mapType}) {
  const projectParams = useProjectParams();
  const {t} = useTranslation();
  const layerType = getLayerType(projectParams.mapLayer);
  const displayedAntennas = useDisplayedAntennas();
  const [mapLayerSwitchState, setMapLayerSwitchState] = useMapLayerSwitchState();
  const selectedTechnology = useSelectedTechnology();

  const colorTitle = {
    RSSI: t("RSSI_Level"),
    MCS: t("MCS_Level"),
    Bit_Rate: t("Bit_Rate_Level"),
    RSRP: t("RSRP_Level"),
    RSRQ: t("RSRQ_Level"),
  };
  try {
    const {mapLayer, channelBW, rssiThreshold: minRssi} = projectParams;
    const selectedSmartType =
      displayedAntennas.length > 0
        ? displayedAntennas
            .map((obj) => obj.type)
            .sort(sortSmartTypeString)
            .slice(-1)[0]
        : null;
    const isRssi = Validator.isNumber(minRssi);
    const rssiLevels = getRssiLevels(mapType, mapLayer, channelBW, minRssi);
    let labels = getColorLabels(
      mapType,
      mapLayer,
      [Infinity, ...rssiLevels],
      t,
      selectedSmartType,
      channelBW,
      mapLayerSwitchState,
      selectedTechnology
    );
    labels = mapLayerSwitchState.EM ? labelsColorsInfo.EMMapLayer.values : smartCityLabels.signal; // < < < > > >fdffdfd

    return (
      <ControlContainer>
        <StyledModal>
          <Modal.Header>
            <Modal.Title>{layerType === "RSSI" ? "Flycomm Scale" : "Flycomm Scale"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isRssi ? (
              <ListGroup className={"list-group-colors"}>
                {labels.map(({rssiValue, color, label}) => (
                  <ColoredListItem key={rssiValue} backcolor={color}>
                    {label}
                  </ColoredListItem>
                ))}
              </ListGroup>
            ) : (
              <LoaderSpinner />
            )}
          </Modal.Body>
        </StyledModal>
      </ControlContainer>
    );
  } catch (e) {
    console.error(e);
    return (
      <ControlContainer>
        <div>there is an error here</div>
      </ControlContainer>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    mapType: reduxState.map.mapType,
  };
}

export default connect(mapStateToProps)(ColorMapping);
