import React, {useRef, useEffect} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo1 from "../images/logo/logo-13.png";
import logo2 from "../images/logo/logo-14.png";
import {connect} from "react-redux";
import {SET_CURRENT_DIALOG} from "../store/actionTypes";
import Constants from "../controller/Constants";
import {modals, mapLayers} from "../controller/Constants";
import useProject, {useProjectParams, useSetProject} from "../Hooks/useProject";
import {showCostsTable} from "../store/actionCreators/map";
import NavbarDropdown from "./Common/Dropdown/NavbarDropdown";
import DropdownItem from "./Common/Dropdown/DropdownItem";
import StarsBar from "./Common/StarsBar";
import StarsBarInfo from "./Common/StarsBarInfo";
import {logoutUser} from "../store/actionCreators/user.js";
import {resetProject} from "../store/actionCreators/general";
import {resetProjects, setMapType, setDisplayedSectors} from "../store/actionCreators/map";
import Api from "../controller/ApiManager";
import {A, Div, NavbarHeader, StyledBadge, StyledProgress} from "./NavbarStyles";
import {getLayerType} from "../controller/mapper";
import {useFetchLoader, useMapLayerSwitchState, usePredictionState, useSelectedTechnology, useUserGroups} from "../Hooks/common";
import {useDisplayedAntennasActions} from "../Hooks/displayedSectors";
import ConfigFileManager from "../components/Common/ConfigFileManager";
import {DisplayedAntennasManager as DAM} from "../controller/models/classes";
import {colorMapperNEW} from "../controller/mapper"; // TUDO: Refactor
import {useProjectState} from "../Hooks/useProject";
import styled from "styled-components";

const {smartTypeStrings} = Constants;

const EmptyDiv = styled.div`
  width: 10px;
`;

function getMaximumCoverage(project) {
  return project.sites.reduce((acc, site) => {
    if (!(site.preDesign.sectors.length > 0)) {
      return acc;
    }
    const siteId = site._id;
    const height = Math.max(...site.preDesign.sectors.map((height) => Number(height.height)));
    return [...acc, {site: siteId, height, type: smartTypeStrings[0]}];
  }, []);
}

function Navbar({
  dispatch,
  user,
  lines,
  coverage,
  averageSignal,
  showCostsTable,
  setMapType,
  mapType,
  logoutUser,
  projects,
  resetProject,
  resetProjects,
  ...props
}) {
  const {t} = useTranslation();
  const project = useProject();
  const setProject = useSetProject();
  const projectParams = useProjectParams();
  const userGroups = useUserGroups();
  const isAdmin = userGroups.includes("admin");
  const {rssiThreshold, mapLayer} = projectParams;
  const mapLayerTypeType = getLayerType(mapLayer);
  const showCoverage = mapType !== Constants.mapTypes.C2I;
  const {isAuthenticated} = user;
  const {BIT_RATE_CAPACITY, BIT_RATE_COVERAGE} = mapLayers;
  const isBitRate = [BIT_RATE_CAPACITY, BIT_RATE_COVERAGE].includes(mapLayer);
  const [fetching, setFetchLoader] = useFetchLoader();
  const configFileManagerRef = useRef(null);
  const {displayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions();
  const [mapLayerSwitchState, setMapLayerSwitchState] = useMapLayerSwitchState();
  const selectedTechnology = useSelectedTechnology();
  const [predictionState, setPredictionState] = usePredictionState();

  const timeoutRef = useRef();
  const {color: badgeColor, label: badgeLabel, starRate} = getColoredLabels(averageSignal);

  console.log({badgeColor, badgeLabel});
  const isProd = process.env.REACT_APP_STAGE === "prod"; //const isProd = Constants.STAGE === "prod";

  useEffect(() => {
    // delete!
    // update mapLayer when mapLayerSwitchState changes
    const RSRP = "RSRP - Coverage Optimized";
    const RSSI = "RSSI - Coverage Optimized";
    async function handleUseEffect() {
      if (project._id === "") return;
      if (mapLayerSwitchState.EM) {
        if (!(project.currentMapLayer === RSSI)) {

          const newProject = await Api.Project.editProject({...project, currentMapLayer: RSSI});
          const updatedProject = {...project, currentMapLayer: newProject.currentMapLayer};
          setProject(updatedProject);
        }

        const newDisplayedAntennas = DAM.setDisplayAll(displayedAntennas, true);
        updateDisplayedAntennas(newDisplayedAntennas); 
      } else {
        if (!(project.currentMapLayer === RSRP)) {

          const newProject = await Api.Project.editProject({...project, currentMapLayer: RSRP});
          const updatedProject = {...project, currentMapLayer: newProject.currentMapLayer};
          setProject(updatedProject);
        }

        const newDisplayedAntennas = DAM.setDisplayAll(displayedAntennas, false);
        updateDisplayedAntennas(newDisplayedAntennas);
      }
    }
    handleUseEffect();
  }, [mapLayerSwitchState]);

  function openDialog(dialogName) {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: dialogName,
    });
  }

  function openAddProjectDialog(ev) {
    openDialog(modals.ADD_PROJECT);
  }

  function getColoredLabels(signal) {
    // clean and refactor
    const ans = colorMapperNEW(signal, mapLayerSwitchState, selectedTechnology);
    return ans || {cplor: "#ffff", label: ""}; // TUDO: Refactor
  }
  function myStarRate() {
    // clean and refactor
    const ans = colorMapperNEW(averageSignal, mapLayerSwitchState, selectedTechnology);
    // console.log({ans, averageSignal, mapLayerSwitchState});
    const ansy = ans?.starRate; // TUDO: Refactor
    // console.log({ansy});
    return ansy;
  }
  function between(x, max, min) {
    return x >= min && x <= max;
  }
  function getStarRate(signal) {
    // clean and refactor
    if (signal > -80) return 5;
    if (between(signal, -80, -90)) return 4;
    if (between(signal, -90, -100)) return 3;
    if (between(signal, -100, -110)) return 2;
    if (signal < -110) return 1;
  }

  //   =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  function handleMapLayerSwitchState(val) {
    // this func shuld update:
    // when mapLayerSwitchState changes to bestServer, this useEffect updates the displayedAntennas display prop

    if (val === "EM" && predictionState) {
      setPredictionState(false);
      setTimeout(() => {
        setMapLayerSwitchState({...mapLayerSwitchState, EM: true, samples: false, bestServer: false});
      }, 0);
      return;
    }
    const state = {EM: false, samples: false, bestServer: false};
    state[val] = true;
    if (timeoutRef.current) clearTimeout(timeoutRef.current); // ????????????????????????????????????
    timeoutRef.current = setTimeout(() => {
      setMapLayerSwitchState(state); // TUDO: Refactor
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }, 0);
  }
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  // =--00--=-0090-=0-okpoijkvrti596g7jjjjjjj5g87878j65jgj65gj8j5g8jj85gj5jjmtdffsrrgrgthj
  function openDisplayCoverage(ev) {
    //
    openDialog(modals.DISPLAY_COVERAGE);
  }

  function showProjects() {
    openDialog(modals.PROJECT_LIST);
  }

  function openEditProjectDialog() {
    openDialog(modals.EDIT_PROJECT);
  }

  function resetBins() {
    updateDisplayedAntennas([]);
  }

  function setBestSever() {
    setMapType(Constants.mapTypes.BEST_SERVER);
  }

  function setC2I() {
    setMapType(Constants.mapTypes.C2I);
  }

  function clusterProject() {
    openDialog(modals.CLUSTER);
  }

  function openLoginDialog() {
    openDialog(modals.LOGIN);
  }

  function logout() {
    // UserApi.logout(user.user.email).then(console.log);
    resetProject();
    resetProjects();
    logoutUser();
  }

  function onChoseSites() {
    openDialog(modals.CHOSE_2_SITES);
  }

  function onDisplayCoverage() {
    openDisplayCoverage();
  }

  return (
    <NavbarHeader id="page-header">
      <nav>
        <div className="left">
          <div className="secondary-nav">
            {!user.isAuthenticated ? <a onClick={openLoginDialog}>{t("Sign_In")}</a> : <a onClick={logout}>{t("Logout_")}</a>}
            {/*<a onClick={openRegisterDialog} className="">{t('Register')}</a>*/}
          </div>
          {isAuthenticated && (
            <>
              <div className="primary-nav has-mega-menu">
                <ul className="navigation">
                  <NavbarDropdown title={t("Projects")} onClick={showProjects}>
                    <DropdownItem
                      title={
                        <>
                          <i className="fa fa-sitemap nav-icon" /> <span>{t("Add_Project")}</span>
                        </>
                      }
                      onClick={openAddProjectDialog}
                      disabled={!isAdmin}
                    />
                    <DropdownItem
                      title={
                        <>
                          <i className="fa fa-pencil-square-o nav-icon" />
                          <span>{t("Edit_Project")}</span>
                        </>
                      }
                      onClick={openEditProjectDialog}
                      disabled={!isAdmin}
                    />
                    <DropdownItem title={t("List_")} icon={<i className="fa fa-list-ul nav-icon" />}>
                      {projects.map((project) => (
                        <li key={project._id}>
                          <Link to={"/projects/" + project._id} className={`add-listing`}>
                            <i className="fa fa-map-marker nav-icon" />
                            <span>
                              {project.name
                                .split(" ")
                                .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
                                .join(" ")}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </DropdownItem>
                  </NavbarDropdown>
                  <NavbarDropdown title={t("Controls")}>
                    {!isProd && (
                      <React.Fragment>
                        <DropdownItem disabled={!isAdmin} title={t("Map_Type")} icon={<i className="fa fa-globe nav-icon" />}>
                          <DropdownItem disabled={!isAdmin} title={t("Best_Server")} onClick={setBestSever} />
                          <DropdownItem disabled={!isAdmin} title={t("C_2_I")} onClick={setC2I} />
                        </DropdownItem>
                        {/* <DropdownItem disabled={!isAdmin} title={t("Setting_")} icon={<i className={"fa fa-cog nav-icon"} />}> */}
                        <DropdownItem disabled={!isAdmin } title={t("Setting_")} icon={<i className={"fa fa-cog nav-icon"} />}>
                          <DropdownItem disabled={!isAdmin } title={t("Rearrange_Bins")} onClick={clusterProject} />
                          <DropdownItem
                            disabled={!isAdmin}
                            title={t("import_project_file_")}
                            onClick={() => configFileManagerRef.current.uploadFile()}
                          />
                          <DropdownItem
                            disabled={!isAdmin}
                            title={t("export_project_file_")}
                            onClick={() => configFileManagerRef.current.downloadFile()}
                          />
                          <ConfigFileManager ref={configFileManagerRef} />
                        </DropdownItem>
                        <DropdownItem
                          disabled={!isAdmin}
                          title={t("Reset_Display")}
                          icon={<i className="fa fa-window-close-o nav-icon" />}
                          onClick={resetBins}
                        />
                        <DropdownItem
                          disabled={!isAdmin}
                          title={t("Tech_Conversion")}
                          icon={<i className="fa fa-magic nav-icon" />}
                          onClick={() => openDialog(modals.TECH_CONVERSION)}
                        />
                      </React.Fragment>
                    )}
                  </NavbarDropdown>
                </ul>
              </div>
              {mapType === Constants.mapTypes.BEST_SERVER && (
                <>
                  <div className="third-nav">
                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                      active={mapLayerSwitchState.EM}
                      onClick={() => handleMapLayerSwitchState("EM")}
                    >
                      <img src="/assets/img/heatmap icon.jpg" className="img-icon" />
                      <span>{t("heat_map_")}</span>
                    </A>
                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                      active={mapLayerSwitchState.samples}
                      onClick={() => handleMapLayerSwitchState("samples")}
                    >
                      <i className="fa fa-wifi cellular-icon" />
                      <span>{t("cellular_map_")}</span>
                    </A>

                    <A
                      className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                      active={mapLayerSwitchState.bestServer}
                      onClick={() => handleMapLayerSwitchState("bestServer")}
                    >
                      <i className="fa fa-object-group" />
                      <span>{t("best_server_map_")}</span>
                    </A>
                  </div>
                </>
              )}

              <Div>
                {t("Rate") + " "}
                <StarsBarInfo
                  starRate={myStarRate()}
                  badgeColor={badgeColor}
                  badgeLabel={badgeLabel}
                  showBorder={badgeLabel === "Very Low EM"}
                  main
                />
                {!mapLayerSwitchState.EM && (
                  <div className="primary-nav has-mega-menu">
                    <ul className="navigation">
                      <NavbarDropdown title={""}>
                        <DropdownItem
                          title={"Voice Call"}
                          icon={<i className={"fa fa-cog nav-icon"} />}
                          content={
                            <StarsBarInfo
                              // starRate={getStarRate(averageSignal)}
                              badgeColor={getColoredLabels(averageSignal + 2).color}
                              badgeLabel={getColoredLabels(averageSignal + 2).label}
                              showPercentage
                              title={"Voice Call"}
                            />
                          }
                        />
                        <DropdownItem
                          title={"Email"}
                          icon={<i className={"fa fa-cog nav-icon"} />}
                          content={
                            <StarsBarInfo
                              // starRate={getStarRate(averageSignal)}
                              badgeColor={getColoredLabels(averageSignal - 8).color}
                              badgeLabel={getColoredLabels(averageSignal - 8).label}
                              showPercentage
                              title={"Email"}
                            />
                          }
                        />
                        <DropdownItem
                          title={"Email with attachment"}
                          icon={<i className={"fa fa-cog nav-icon"} />}
                          content={
                            <StarsBarInfo
                              // starRate={getStarRate(averageSignal)}
                              badgeColor={getColoredLabels(averageSignal - 8).color}
                              badgeLabel={getColoredLabels(averageSignal - 8).label}
                              showPercentage
                              title={"Email with attachment"}
                            />
                          }
                        />

                        <DropdownItem
                          title={"Web Browsing - Webinar"}
                          icon={<i className={"fa fa-cog nav-icon"} />}
                          content={
                            <StarsBarInfo
                              // starRate={getStarRate(averageSignal)}
                              badgeColor={getColoredLabels(averageSignal - 6).color}
                              badgeLabel={getColoredLabels(averageSignal - 6).label}
                              showPercentage
                              title={"Web Browsing - Webinar"}
                            />
                          }
                        />
                        {selectedTechnology !== "3G" && (
                          <DropdownItem
                            title={"Video Streaming HD - Zoom"}
                            icon={<i className={"fa fa-cog nav-icon"} />}
                            content={
                              <StarsBarInfo
                                // starRate={getStarRate(averageSignal)}
                                badgeColor={getColoredLabels(averageSignal - 6).color}
                                badgeLabel={getColoredLabels(averageSignal - 6).label}
                                showPercentage
                                title={"Video Streaming HD - Zoom"}
                              />
                            }
                          />
                        )}
                        {selectedTechnology !== "3G" && (
                          <DropdownItem
                            title={"Video Streaming FHD - Youtube"}
                            icon={<i className={"fa fa-cog nav-icon"} />}
                            content={
                              <StarsBarInfo
                                // starRate={getStarRate(averageSignal)}
                                badgeColor={getColoredLabels(averageSignal - 6).color}
                                badgeLabel={getColoredLabels(averageSignal - 6).label}
                                showPercentage
                                title={"Video Streaming FHD - Youtube"}
                              />
                            }
                          />
                        )}
                        {selectedTechnology !== "3G" && (
                          <DropdownItem
                            title={"Video Streaming 4K - Youtube"}
                            icon={<i className={"fa fa-cog nav-icon"} />}
                            content={
                              <StarsBarInfo
                                // starRate={getStarRate(averageSignal)}
                                badgeColor={getColoredLabels(averageSignal - 3).color}
                                badgeLabel={getColoredLabels(averageSignal - 3).label}
                                showPercentage
                                title={"Video Streaming 4K - Youtube"}
                              />
                            }
                          />
                        )}
                        {selectedTechnology !== "3G" && (
                          <DropdownItem
                            title={"Autonomous Vehicles"}
                            icon={<i className={"fa fa-cog nav-icon"} />}
                            content={
                              <StarsBarInfo
                                // starRate={getStarRate(averageSignal)}
                                badgeColor={getColoredLabels(averageSignal - 3).color}
                                badgeLabel={getColoredLabels(averageSignal - 3).label}
                                showPercentage
                                title={"Autonomous Vehicles"}
                              />
                            }
                          />
                        )}
                        {selectedTechnology !== "3G" && (
                          <DropdownItem
                            title={"Autonomous Drones"}
                            icon={<i className={"fa fa-cog nav-icon"} />}
                            content={
                              <StarsBarInfo
                                // starRate={getStarRate(averageSignal)}
                                badgeColor={getColoredLabels(averageSignal - 3).color}
                                badgeLabel={getColoredLabels(averageSignal - 3).label}
                                showPercentage
                                title={"Autonomous Drones"}
                              />
                            }
                          />
                        )}
                      </NavbarDropdown>
                    </ul>
                  </div>
                )}
              </Div>
            </>
          )}
        </div>
        <div className="right">
          <a href="/" className="brand">
            <img className="logo-icon" src={logo1} alt="flycomm icon" />
            <img className="logo-text" src={logo2} alt="flycomm text" />
          </a>
        </div>
      </nav>
    </NavbarHeader>
  );
}

function mapStateToProps(reduxState) {
  return {
    lines: reduxState.map.lines,
    coverage: reduxState.map.coverage,
    averageSignal: reduxState.map.averageSignal,
    mapType: reduxState.map.mapType,
    user: reduxState.user,
    projects: reduxState.map.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    showCostsTable: (isCostsTable) => dispatch(showCostsTable(isCostsTable)),
    setMapType: (mapType) => dispatch(setMapType(mapType)),
    logoutUser: () => dispatch(logoutUser()),
    resetProject: () => dispatch(resetProject()),
    resetProjects: () => dispatch(resetProjects()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
