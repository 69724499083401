import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useProjectParams, useProjectState} from "../../Hooks/useProject";
import styled from "styled-components";
import {PROVIDERS , TECHNOLOGYS, ARGENTINA, userEnvironments} from "../../controller/Constants";
import {useDisplayedAntennasActions} from "../../Hooks/displayedSectors";
import {LocalDisplayedAntennasManager as LDAM, DisplayedAntennasManager as DAM} from "../../controller/models/classes";
import {useSetUserEnvironment, useUserEnvironment} from "../../Hooks/useUserEnvironment";
import CardCollapse from "./CardCollapse";

import {useTimeOfDay, useSetTimeOfDay} from "../../Hooks/useTimeOfDay";
import {timeOfDay as timeOfDayConstants} from "../../controller/Constants";
import {Container} from "../StyledComponents/cardCollapseContainer";
import BSButton from "react-bootstrap/Button";
import {useMapLayerSwitchState, useSelectedTechnology, useSetSelectedTechnology, useFilterStateActins} from "../../Hooks/common";
const Position = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 200;
`;
const StyledContent = styled.div``;
// d9dbdd
const ClearMapButton = styled(BSButton)`
  &&&,
  &&&:focus {
    font-family: "Lato", sans-serif;
    border: 2px #8db6f7 solid;
    background-color: #ffff;
    color: #413c4a;
    margin-top: 5px;
    &:hover {
      background-color: #8db6f7;
      color: #ffff;
    }
  }

  /* &&,
  &&:focus,
  &&:focus-visible {
    font-family: "Lato", sans-serif;
    border: 2px #8db6f7 solid;
    background-color: #ffff;
    color: #413c4a;
    margin-top: 5px;
  } */
`;
// const ClearMapButton = styled.button`
//   &&,
//   &&:active {
//     font-family: "Lato", sans-serif;
//     border: 2px #8db6f7 solid;
//     background-color: #ffff;
//     color: #413c4a;
//     margin-top: 5px;
//     /* width: 33%;
//     white-space: nowrap;
//     background: none;
//     color: ${({active}) => (active ? "#f5cb42" : "inherit")};
//     border: none;
//     padding: 0;
//     font: inherit;
//     cursor: pointer;
//     outline: inherit; */
//   }
//   &&:active {
//     color: #f5cb42;
//   }
// `;

function FilterControls({}) {
  const [project, setProject] = useProjectState();
  const {displayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions();
  const [filterState, setFilterState] = useFilterStateActins();
  const userEnvironment = useUserEnvironment();
  const setUserEnvironment = useSetUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const selectedTechnology = useSelectedTechnology();
  const setSelectedTechnology = useSetSelectedTechnology();
  const setTimeOfDay = useSetTimeOfDay();
  const [mapLayerSwitchState, setMapLayerSwitchState] = useMapLayerSwitchState();
  const {t} = useTranslation();
  const {MEN, CAR, HOME} = userEnvironments;
  const {MORNING, AFTERNOON, EVENING} = timeOfDayConstants;
  const allSitesNames = project.sites.map((site) => site.displayName);

  // const [filterState, setFilterState] = useState({
  //   provider: "Cellcom",
  //   technology: selectedTechnology,
  //   date: "11-2021",
  //   site: allSitesNames[0],
  // });
  const allSitesNamesWithDuplicate = project.sites.map((site) => site.displayName.split(",")[1]);

  const uniqSitesNames = [...new Set([...allSitesNamesWithDuplicate])];
  // const TEMPORARY_DATES = ["11-2021", "12-2021", "01-2022"];
  const TEMPORARY_DATES = uniqSitesNames;
  const devicesValues = {
    title: "Devices",
    isIcon: false,
    isOneRow: true,
    values: allSitesNames.map((site) => ({
      value: site,
      label: site,
      isSingleItemInRow: true,
      active: filterState?.site === site,
      onClick: (val) => setFilterState({...filterState, site: val}),
      disabled: false,
    })),
  };
  const providerValues = {
    title: "Provider",
    isIcon: false,
    values: PROVIDERS.map((provider) => ({
      value: provider,
      label: provider,
      active: mapLayerSwitchState.bestServer ? true : filterState?.provider === provider, // mapLayerSwitchState
      onClick: (val) => setFilterState({...filterState, provider: val}),
      disabled: false,
    })),
  };
  const technologyValues = {
    title: "Technology",
    isIcon: false,
    values: TECHNOLOGYS.map((tech) => ({
      value: tech,
      label: tech,
      active: filterState?.technology === tech,
      onClick: (val) => setFilterState({...filterState, technology: val}),
      disabled: false,
      // disabled: tech === "5G" ? true : false,
    })),
  };
  const datesValues = {
    title: "Date",
    isIcon: false,
    values: TEMPORARY_DATES.map((date) => ({
      value: date,
      label: date,
      active: filterState?.date === date,
      onClick: (val) => setFilterState({...filterState, date: val}),
      disabled: false,
    })),
  };
  const environmentValues = {
    title: "Environment",
    isIcon: true,
    values: [MEN, CAR, HOME].map((env) => ({
      value: env,
      label: env,
      iconLabel: env === MEN ? "male" : env === CAR ? "car" : "home",
      active: userEnvironment === env,
      onClick: (val) => setUserEnvironment(val),
      disabled: false,
    })),
  };
  const timeOfDayValues = {
    title: "Day time",
    isIcon: false,
    values: [MORNING, AFTERNOON, EVENING].map((time) => ({
      value: time,
      label: time,
      active: timeOfDay === time,
      onClick: (val) => setTimeOfDay(val),
      disabled: false,
    })),
  };
  const mobileIdValues = {
    isIcon: false,
    title: "Site",
    values: [],
    disabled: false,
  };

  const values = [providerValues, technologyValues, datesValues, environmentValues, timeOfDayValues, mobileIdValues];

  // useEffect(() => {
  //   handlChange();
  // }, [filterState]);

  useEffect(() => {
    setSelectedTechnology(filterState.technology);
  }, [filterState.technology]);

  function onClearMap() {
    setFilterState({
      provider: "",
      technology: "",
      date: "",
    });
    updateDisplayedAntennas([]);
  }
  function handlChange() {
    if (project._id === "6242e2f96c3c440009632b27") return handlChange_Uri_Shrir();
    if (project._id === "6243012a5e381b00098caa65") return handlChange_Arfel_Yakir();
    // debugger;
    // if (mapLayerSwitchState.bestServer) {
    // const sites = project.sites.filter((site) => site.displayName.split(",")[1] === filterState.date);
    // const sectors = sites.flatMap((site) =>
    //   [...site.preDesign.sectors, ...site.postDesign.sectors].filter((sector) => filterState.technology.startsWith(sector.height))
    // );
    // const sectorsIds = sectors.map((sector) => sector._id);
    // const displayedAntenna = displayedAntennas.filter((antenna) => sectorsIds.includes(antenna.sectorId));
    // console.log({displayedAntenna, sectors});
    // updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds));
    // return;
    // }
    const sites = project.sites.filter((site) => site.displayName.split(",")[0] === filterState.provider);
    const site = sites.find((site) => site.displayName.split(",")[1] === filterState.date);
    if (!site) {
      updateDisplayedAntennas(DAM.setDisplayByIds(displayedAntennas, []));
      return;
    }
    const sectors = [...site.preDesign.sectors, ...site.postDesign.sectors].filter((sector) => filterState.technology.startsWith(sector.height));
    const sectorsIds = sectors.map((sector) => sector._id);
    const displayedAntenna = displayedAntennas.filter((antenna) => sectorsIds.includes(antenna.sectorId));
    // const displayIds = [sector._id];
    updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds));
  }
  function handlChange_Uri_Shrir() {
    const site = project.sites.find((site) => site.displayName === filterState.site);
    if (!site) {
      updateDisplayedAntennas(DAM.setDisplayByIds(displayedAntennas, []));
      return;
    }
    const sectors = [...site.preDesign.sectors, ...site.postDesign.sectors];
    const sectorsIds = sectors.map((sector) => sector._id);
    const displayedAntenna = displayedAntennas.filter((antenna) => sectorsIds.includes(antenna.sectorId));
    // const displayIds = [sector._id];
    updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds));
  }
  function handlChange_Arfel_Yakir() {
    const site = project.sites.find((site) => site.displayName === filterState.site);
    if (!site) {
      updateDisplayedAntennas(DAM.setDisplayByIds(displayedAntennas, []));
      return;
    }
    const sectors = [...site.preDesign.sectors, ...site.postDesign.sectors].filter((sector) => filterState.technology.startsWith(sector.height));
    const sectorsIds = sectors.map((sector) => sector._id);
    const displayedAntenna = displayedAntennas.filter((antenna) => sectorsIds.includes(antenna.sectorId));
    // const displayIds = [sector._id];
    updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds));
  }
  return (
    <Position>
      <StyledContent>
        {project._id === "6242e2f96c3c440009632b27" ||
          (project._id === "6243012a5e381b00098caa65" &&
            devicesValues.values.map((val) => <CardCollapse title={"device"} values={[val]} isIcon={false} key={val.value} />))}
        {values.map((value) => (
          <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} key={value.title} isOneRow={value.isOneRow} />
        ))}
        <Container>
          <ClearMapButton onClick={onClearMap}>Clear Map</ClearMapButton>
        </Container>
      </StyledContent>
    </Position>
  );
}
{
  // process.env.REACT_APP_STAGE === "dev" && <MapLayerControls />;
}
export default FilterControls;
