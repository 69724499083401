import React from "react";
import {OverlayView} from "@react-google-maps/api";
import TableBS from "react-bootstrap/Table";
import SmallModal from "../StyledComponents/SmallModal";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Constants from "../../controller/Constants";
import {mapLayers} from "../../controller/Constants";
import {getBitRateFromBin, getLayerType, reduceBinSitesToHighestRssi} from "../../controller/mapper";
import {useProjectParams} from "../../Hooks/useProject";

const StyledTable = styled(TableBS)`
  &.table {
    margin: 0;
  }
  &&&&.table-title {
    background: "#d7d9db";
  }
`;

function BinDetails({position, mapType, details, signal, location, show, fromSites}) {
  console.log({details, fromSites});
  const {t} = useTranslation();
  const projectParams = useProjectParams();
  const isMoreThenOneBin = fromSites.length > 1;

  function getBinsDetails() {
    if (isMoreThenOneBin) {
      // best server mode
      return getBinsDetailsBestServer();
    }
    const {mapLayer} = projectParams;
    const isBitRateCapacity = projectParams.mapLayer === mapLayers.BIT_RATE_CAPACITY;
    const bestServerObj = fromSites.reduce(reduceBinSitesToHighestRssi, {rssi: -Infinity});
    const bestServer = bestServerObj.site;
    let detailsRes = [{key: "best_server", value: bestServer}];
    if (isBitRateCapacity) {
      detailsRes.push({key: "bit_rate_b", value: getBitRateFromBin(details, fromSites, projectParams)});
    }
    let key = "rssi";
    const signalType = getLayerType(mapLayer);
    if (!["MCS", "Bit_Rate"].includes(signalType)) {
      key = signalType.toLowerCase();
    }
    if (mapType === Constants.mapTypes.BEST_SERVER) return [...detailsRes, {key: key, value: Number(signal)}];
    if (mapType === Constants.mapTypes.C2I) return [...detailsRes, {key: key + "_gap", value: Number(signal.toFixed(3))}];
  }
  function getBinsDetailsBestServer() {
    const signalsList = fromSites.map((bin) => ({
      key: bin.site.split(",")[0],
      value: bin.signal,
      index: getOrderedIndex(bin.signal, fromSites),
    }));

    return signalsList;
  }
  function getOrderedIndex(signal, list) {
    const signals = list.map((bin) => bin.signal);
    if (Math.min(...signals) === signal) return 3;
    if (Math.max(...signals) === signal) return 1;
    return 2;
  }
  const binDetails = getBinsDetails();
  return (
    <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <SmallModal>
        <StyledTable responsive size="sm">
          {details && (
            <tbody>
              {isMoreThenOneBin &&
                binDetails
                  .filter((bin) => bin.index === 1)
                  .map(({key, value}) => (
                    <tr key={key}>
                      <td>{t(key)}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
              {isMoreThenOneBin &&
                binDetails
                  .filter((bin) => bin.index === 2)
                  .map(({key, value}) => (
                    <tr key={key}>
                      <td>{t(key)}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
              {isMoreThenOneBin &&
                binDetails
                  .filter((bin) => bin.index === 3)
                  .map(({key, value}) => (
                    <tr key={key}>
                      <td>{t(key)}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
              {!isMoreThenOneBin &&
                binDetails.map(({key, value}) => (
                  <tr key={key}>
                    <td>{t(key)}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              {/* <tr key={key}>
                <td>{t(key)}</td>
                <td>{value}</td>
              </tr>
              <tr key={key}>
                <td>{t(key)}</td>
                <td>{value}</td>
              </tr>
              <tr key={key}>
                <td>{t(key)}</td>
                <td>{value}</td>
              </tr>
              {binDetails.map(({key, value}) => (
                <tr key={key}>
                  <td>{t(key)}</td>
                  <td>{value}</td>
                </tr>
              ))} */}
            </tbody>
          )}
        </StyledTable>
      </SmallModal>
    </OverlayView>
  );
}

export default BinDetails;
