import React, {useRef, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {setMapState} from "../../store/actionCreators/map";
import {connect} from "react-redux";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import {useMapLayerSwitchState, usePolygonPath, usePredictionState} from "../../Hooks/common";
import {openDialog} from "../../store/actionCreators/general";
import {useDispatch} from "react-redux";
import Constants from "../../controller/Constants";

const Div = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center;
`;

function PredictionSwitch() {
  const {t} = useTranslation();
  const [predictionState, setPredictionState] = usePredictionState();
  const polygonPath = usePolygonPath();
  const dispatch = useDispatch();
  const [mapLayerSwitchState, setMapLayerSwitchState] = useMapLayerSwitchState();

  const timeoutRef = useRef();
  const choices = [
    {value: "prediction", label: t("predict_l"), checked: predictionState},
    {value: "samples", label: t("samples_l"), checked: !predictionState},
  ];
  return (
    <Div>
      <ButtonsSelect
        round={false}
        values={choices}
        onSelect={(obj) => {
          const {value: val, checked} = obj;
          // debugger;
          //
          if (mapLayerSwitchState.EM) {
            setMapLayerSwitchState({...mapLayerSwitchState, EM: false, samples: true});
            setTimeout(() => {
              setPredictionState(val === "prediction");
            }, 0);
            return;
          }

          ///
          const samplesToPrediction = val === "prediction" && !checked;
          if (samplesToPrediction && polygonPath.length < 2) {
            dispatch(openDialog(Constants.modals.CHOOSE_POLYGON_ALERT));
            return;
          }
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            setPredictionState(val === "prediction");
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }, 0);
        }}
      />
    </Div>
  );
}

function mapStateToProps(reduxState) {
  return {};
}

export default connect(mapStateToProps, {setMapState})(PredictionSwitch);
