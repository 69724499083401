import React from "react";
import styled from "styled-components";
import {colorMapperNEW} from "../../controller/mapper";
import {useTechnologiesCoverage} from "../../Hooks/common";
import {StyledBadge} from "../NavbarStyles";
import StarsBar from "./StarsBar";

const Wrapper = styled.div`
  display: flex;
`;
function between(x, max, min) {
  return x >= min && x <= max;
}
function getStarRate(signal) {
  // clean and refactor
  // debugger;
  if (between(signal, 20, 0)) return 1;
  if (between(signal, 40, 20)) return 2;
  if (between(signal, 60, 40)) return 3;
  if (between(signal, 80, 60)) return 4;
  if (between(signal, 100, 80)) return 5;
  if (signal < 0 || signal > 100) throw Error("Error calculating percentages");
}
function getColorAndLable(signal) {
  // clean and refactor
  // debugger;
  if (between(signal, 20, 0)) return {color: "#ed2024", label: "Poor Coverage", starRate: 1}; // red
  if (between(signal, 40, 20)) return {color: "#fec00f", label: "Low Coverage", starRate: 2}; // orange;
  if (between(signal, 60, 40)) return {color: "#f6eb14", label: "Moderate. Coverage", starRate: 3}; // yellow;
  if (between(signal, 80, 60)) return {color: "#94c954", label: "Good Coverage", starRate: 4}; // light green;
  if (between(signal, 100, 80)) return {color: "#00b14f", label: "High Coverage", starRate: 5}; // green;
  if (signal < 0 || signal > 100) throw Error("Error calculating percentages");
}
function StarsBarInfo({starRate, badgeColor, badgeLabel, showPercentage, title, showBorder, main}) {
  const technologiesCoverage = useTechnologiesCoverage();
  const color = main ? badgeColor : getColorAndLable(technologiesCoverage[title])?.color;
  const label = main ? badgeLabel : getColorAndLable(technologiesCoverage[title])?.label;

  return (
    <Wrapper>
      <StyledBadge showBorder={showBorder} padding variant="none" color={color || "#ffff"}>
        {" "}
        {label ? `${label} City` : ""}
      </StyledBadge>
      <StarsBar color={color} starRate={starRate || getStarRate(technologiesCoverage[title])} />
      {showPercentage && title && `${technologiesCoverage[title]} %`}
    </Wrapper>
  );
}

export default StarsBarInfo;
