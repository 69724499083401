import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {
    setDisplayedAntennas,
    updateDisplayedAntennas
} from "../store/actionCreators/map";
import useProject from "./useProject";
import {DisplayedAntennasManager as DAM} from "../controller/models/classes"
import {useLocation} from "react-router-dom";
import { usePrevious } from "./common";

/**
 * description Displayed Sectors:
 * these are objects that indicate the sectors wer want to display in the map,
 * each sector when display = true should display the bins of corresponding sector (from sectorId property)
 *
 *
 *
 * displayed sector structure:
 * {
 *      siteId:  ObjectId (String),
 *      sectorId: ObjectId (String),
 *      antennaModel:  <Antenna Type> String (example: 'TYPE_1X1' ),
 *      display: Boolean (New!!),
 *      conversion:{
 *          from:<Technology>> String,
 *          to:<Technology> String
 *      }
 * }
 */


/*
 * hook to restart the global redux  object when application starts.
 */
export function useInitDisplayedAntennas() {
    const project = useProject();
    const location = useLocation();
    const setDisplayedAntennas = useSetDisplayedAntennas();
    const cleanDisplayedAntennas = useCleanDisplayedAntennas();
    const displayedAntennas = useDisplayedAntennas();
    const prevProjectId = usePrevious(project._id);

    useEffect(() => {
        // HOOK DESCRIPTION: if the Project hase not changed, leave the displayedAntennas as is, otherwise reset them.
         if(prevProjectId === project._id){
            return;
        }
        const displayedAntennas = DAM.fromProject(project)
        if (displayedAntennas.length === 0) {
            cleanDisplayedAntennas();
            return;
        }
        setDisplayedAntennas(displayedAntennas);
    }, [project])
}

export function useCleanDisplayedAntennas() {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(setDisplayedAntennas([]));
    }, [dispatch])
}

export function useResetDisplayedAntennas() {
    const dispatch = useDispatch();
    const displayedAntennas = useDisplayedAntennas();
    return useCallback(() => {
        dispatch(setDisplayedAntennas(DAM.setDisplayAll(displayedAntennas,false)));
    }, [dispatch])
}


export function useDisplayedAntennas() {
    return useSelector(state => state.map.displayedAntennas);
}

function useSetDisplayedAntennas() {
    const dispatch = useDispatch();
    return useCallback((displayedAntennas) => {
        dispatch(setDisplayedAntennas(displayedAntennas))
    }, [dispatch]);
}

function useUpdateDisplayedAntennas() {
    const dispatch = useDispatch();
    return useCallback((displayedAntennas) => {
        dispatch(updateDisplayedAntennas(displayedAntennas))
    }, [dispatch]);
}

export function useDisplayedAntennasActions() {
    return {
        displayedAntennas: useDisplayedAntennas(),
        updateDisplayedAntennas: useUpdateDisplayedAntennas(),
        setDisplayedAntennas: useSetDisplayedAntennas(),
        cleanDisplayedAntennas: useCleanDisplayedAntennas(),
        resetDisplayedAntennas: useResetDisplayedAntennas(),
    }
}