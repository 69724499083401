import React from "react";
import styled from "styled-components";

const StarsWrapper = styled.div`
  color: #dcdcdc;
  margin-right: 2px;

  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black;

  .colored {
    /* color: ${({color}) => color}; */
    color: #d4af37;
  }
  .fa-star {
    margin-right: 1px;
  }
`;
function StarsBar({starRate, color}) {
  return (
    <StarsWrapper color={color}>
      {[1, 2, 3, 4, 5].map((index) => {
        if (index <= starRate) {
          return <i className={"fa fa-star colored"} aria-hidden="true"></i>;
        } else {
          return <i className={"fa fa-star"} aria-hidden="true"></i>;
        }
      })}
    </StarsWrapper>
  );
}

export default StarsBar;
