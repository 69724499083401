const Constants = require("../Constants");
const {mapLayerValues} = require("../Constants");
const mongoose = require("mongoose");
const { technologies, frequencyBands, channelBWs, polarizationTypes} = Constants;
const projectParamsSchema = new mongoose.Schema({
    created: {
        type: Date,
        default: Date.now
    },
    lastModified: {
        type: Date,
        default: Date.now
    },
    mapLayer: {
        type: String,
        enum: mapLayerValues,
        default: 'RSSI - Coverage Optimized',
    },
    project: {
        type: mongoose.Schema.Types.ObjectId,
        // ref: Constants.PROJECT_MODEL
    },
    devParams: {
        samplesReliability: {type: Number, default: 95},
        binResolution: {type: Number, default: 20},
        binReliability: {type: Number, default: 5},//percentile on the old version
        polarizationLoss: {type: Number, default: 0},
        systemGain: {type: Number, default: 0},
        minCoverage: {type: Number, default: 80},
    },
    technology: {type: String, enum: technologies, default: '5G-TDD'},
    frequencyBand: {type: Number, enum: frequencyBands, default: 3600},
    channelBW: {type: Number, enum: channelBWs, default: 60},
    rssiThreshold: {type: Number, default: -95},
    downUpLinkRatio: {type: Number, default: 75},
    antennaPolarization: {type: String, enum: polarizationTypes, default: 'Dual Slant 45deg'},
    legendTable: [
        {
            index: Number,
            color: String,
            rssiGap: Number,
            // gap value is the floor of the gap, the ceil is or
            // inf or the next bigger value(index bigger then this index) smaller value is -Infinity.
            rssiBWGap: Number,
            bitRate: Number,
            MCS: String,
        }
    ]
}, {timestamps: true});
projectParamsSchema.methods.calcCorrectionFactor = function (antennaSmartType, siteTxLoss, siteTxPower) {
    return this.calcAntennaGain(antennaSmartType) - this.systemGain - this.polarizationLoss - siteTxLoss + siteTxPower
};
projectParamsSchema.methods.calcAntennaGain = function (antennaSmartType) {
    const typesTable = {
        TYPE_1X1: {gain: 9, stream: null},
        TYPE_2X2: {gain: 9, stream: 1},
        TYPE_4X4: {gain: 12, stream: 1},
        TYPE_8X8: {gain: 15.5, stream: 2},
        TYPE_8X8_STEERED: {gain: 20, stream: 2},
        TYPE_16X16: {gain: 18, stream: 4},
        TYPE_32X32: {gain: 21, stream: 8},
        TYPE_64X64_STEERED: {gain: 24, stream: 16},
    };
    return typesTable[antennaSmartType].gain;
};
module.exports = projectParamsSchema;