import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modals} from "../controller/Constants";
import Modal from "react-bootstrap/Modal";
import {SET_CURRENT_DIALOG} from "../store/actionTypes";
import {
  Register,
  AddProject,
  ProjectList,
  EditProject,
  EditSite,
  Parameters,
  DisplayCoverage,
  Choose2Sites,
  Cluster,
  ClusterAlert,
  Login,
  ChoosePolygonAlert,
} from "./Dialogs";
import styled from "styled-components";
import {useFetchLoader} from "../Hooks/common";
import {DialogAlertProvider} from "../contexts/DialogAlert";
import TechConversion from "./Dialogs/TechConversion";

const StyledModal = styled(Modal)`
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

function DialogGenerator({}) {
  const dispatch = useDispatch();
  const {currentDialog} = useSelector(MapStateToProps);
  const [closing, setClosing] = useState(false);
  const [previousDialogs, setPreviousDialogs] = useState([]);
  const [fetching, setFetching] = useFetchLoader();

  function closeDialogs() {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: null,
    });
    setClosing(false);
    setPreviousDialogs([]);
  }

  useEffect(() => {
    setPreviousDialogs([...previousDialogs, currentDialog]);
    if (currentDialog === null && fetching) setFetching(false);
  }, [currentDialog]);

  function backDialog() {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: previousDialogs.slice(-2)[0],
    });
    setPreviousDialogs([...previousDialogs].splice(-1, 1));
  }

  function onFetchStart() {
    setFetching(true);
  }

  function onFetchEnd() {
    setFetching(false);
  }

  function setFetchingProp(flag) {
    setFetching(flag);
  }

  const currentDialogComponent = (() => {
    switch (currentDialog) {
      case modals.REGISTER:
        return <Register key={modals.REGISTER} />;
      case modals.ADD_PROJECT:
        return <AddProject key={modals.ADD_PROJECT} onClose={closeDialogs} onBack={backDialog} />;
      case modals.PROJECT_LIST:
        return <ProjectList key={modals.PROJECT_LIST} />;
      case modals.EDIT_PROJECT:
        return <EditProject key={modals.EDIT_PROJECT} />;
      case modals.EDIT_SITE:
        return <EditSite onBack={backDialog} key={modals.EDIT_SITE} />;
      case modals.PARAMETERS:
        return <Parameters key={modals.PARAMETERS} />;
      case modals.CHOSE_2_SITES:
        return Choose2Sites;
      case modals.CLUSTER:
        return <Cluster key={modals.CLUSTER} onClose={closeDialogs} />;
      case modals.CLUSTER_ALERT:
        return <ClusterAlert key={modals.CLUSTER} />;
      case modals.LOGIN:
        return <Login key={modals.LOGIN} />;
      case modals.DISPLAY_COVERAGE:
        return <DisplayCoverage key={modals.DISPLAY_COVERAGE} onClose={closeDialogs} />;
      case modals.TECH_CONVERSION:
        return <TechConversion key={modals.DISPLAY_COVERAGE} onClose={closeDialogs} />;
      case modals.CHOOSE_POLYGON_ALERT:
        return <ChoosePolygonAlert onClose={closeDialogs} />;
      default:
        return null;
    }
  })();
  const DialogObject =
    currentDialogComponent && React.cloneElement(currentDialogComponent, {onFetchStart, onFetchEnd, setFetching: setFetchingProp});
  return (
    <DialogAlertProvider>
      <StyledModal
        className={"dialog-generator"}
        show={!closing && Boolean(currentDialog)}
        onHide={() => {
          setClosing(true);
        }}
        onExited={closeDialogs}
        scrollable
        enforceFocus={false}
      >
        {DialogObject}
      </StyledModal>
    </DialogAlertProvider>
  );
}

function MapStateToProps(reduxState) {
  return {currentDialog: reduxState.general.currentDialog};
}

export default DialogGenerator;
