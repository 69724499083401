import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setTimeOfDay} from "../store/actionCreators/map";

export function useTimeOfDay() {
  const timeOfDay = useSelector((state) => state.map.timeOfDay);
  return timeOfDay;
}

export function useSetTimeOfDay() {
  const dispatch = useDispatch();
  return useCallback((timeOfDay) => dispatch(setTimeOfDay(timeOfDay)), [dispatch]);
}
