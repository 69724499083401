export function spreadHeight(height) {
    const {_id, driveTestFile, height: meters, antennaModels, cost, binsPlacement} = height;
    return [_id, meters, cost, Boolean(binsPlacement), driveTestFile.bucket, driveTestFile.fileId, antennaModels]
}

export function spreadPlacements(sites) {
    try {
        return sites.map(site => site.preDesign.sectors.map(height => (
                height.binsPlacements.map(placement => {
                    const {bins, percentile, fileId, smartType} = placement;
                    return [bins.length, percentile, fileId, smartType].join(',')
                }).join(';')
            )).join(':')
        ).join('-')
    } catch {
        return null;
    }
}

export function spreadSite(site) {
    try {
        const {_id, displayName, location, parameters, displayParams} = site;
        const {lat, lng} = location;
        const {txPower, txLoss, antennaGain} = parameters;
        const {txPower: txPowerDP, txLoss: txLossDP, antennaTypeCode} = displayParams;
        return [_id, displayName, lat, lng, txPower, txLoss, antennaGain, txLossDP, txPowerDP, antennaTypeCode].join(',');
    } catch (e) {
        // todo: take care of edge cases. and avoid return ''
        console.warn(e);
        return '';
    }
}

//without id;
export function spreadHeightState(heightState) {
    const {height, cost, antennaModels, antennaPrices, /*file,#no need in the SectorInputs useEffect*/ inDatabase} = heightState;
    return [height, cost, inDatabase, antennaModels.join(';'), Object.entries(antennaPrices).map(([key, val]) => `${key}:${val}`).join(';')].join(',')
}

export function spreadBounds(bounds) {
    try {
        if (!bounds) return '';
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        return `${northEast.lat()},${northEast.lng()},${southWest.lat()},${southWest.lng()}`
    } catch (e) {
        console.error(e);
        return ''
    }
}