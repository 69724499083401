import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUserEnvironment} from "../store/actionCreators/map";

export function useUserEnvironment() {
    const userEnv = useSelector(state => state.map.userEnvironment);
    return userEnv;
}

export function useSetUserEnvironment() {
    const dispatch = useDispatch();
    return useCallback((environment) => dispatch(setUserEnvironment(environment)), [dispatch]);
 }