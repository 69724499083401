import React from "react";
import {MarkerClusterer, Marker as GoogleMarker, useGoogleMap} from "@react-google-maps/api";
import Marker from "./Marker";
import StaticAntennaMarker from "./StaticAntennaMarker";
import {useMapZoom} from "../../Hooks/map";
import {isLatLngInBounds} from "../../controller/dataOperations";
import Constants from "../../controller/Constants";
const {smartCityShowSiteIcon, showStaticAntennas} = Constants;
const options = {
  imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function filterByMarker(mapZoom) {
  return (site) => {
    if (mapZoom > 15) return true;
    return true;
  };
}

function inScope(mapBounds) {
  return (site) => (mapBounds ? isLatLngInBounds(site.location, mapBounds) : false);
}

function SitesClusterer({sites, chosenSites, mapBounds}) {
  const staticAntennas = [
    {
      location: {lat: 31.82877, lng: 35.1362},
      displayName: "Static Antenna",
    },
    {
      location: {lat: 31.828214661284257, lng: 35.13068439733321},
      displayName: "Static Antenna",
    },
  ];
  const map = useGoogleMap();
  const mapZoom = useMapZoom();
  const sitesInScope = sites.filter(inScope(mapBounds));
  const markerSites = sites.filter(filterByMarker(mapZoom, mapBounds));
  const showOverlays = Boolean(sitesInScope.length < 10);

  function positionMap(location) {
    map.setCenter(location);
    map.setZoom(Math.max(mapZoom + 1, 15));
  }

  return (
    <>
      {/*sites display*/}
      {/* <MarkerClusterer
      // options={options}
      >
        {(clusterer) =>
          !showOverlays &&
          markerSites.map((site, i) => {
            return <GoogleMarker onClick={() => positionMap(site.location)} clusterer={clusterer} position={site.location} />;
          })
        }
      </MarkerClusterer> */}
      {smartCityShowSiteIcon &&
        showOverlays &&
        sitesInScope.map((site, i) => {
          return (
            <Marker key={site.location.lat + "," + site.location.lng} title={chosenSites ? (i === 0 ? "Main" : "Secondary") : ""} site={site} />
          );
        })}

      {showStaticAntennas &&
        staticAntennas.map((site, i) => {
          return (
            <StaticAntennaMarker
              key={site.location.lat + "," + site.location.lng}
              title={chosenSites ? (i === 0 ? "Main" : "Secondary") : ""}
              site={site}
            />
          );
        })}
    </>
  );
}

SitesClusterer = React.memo(SitesClusterer);
export default SitesClusterer;
