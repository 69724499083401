import React, {useRef, useEffect, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {setMapState} from "../../store/actionCreators/map";
import {connect} from "react-redux";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import {usePredictionState} from "../../Hooks/common";
import {useMapLayerSwitchState} from "../../Hooks/common";
import Constants from "../../controller/Constants";
import Api from "../../controller/ApiManager/index";
import {useDisplayedAntennasActions} from "../../Hooks/displayedSectors";
import {DisplayedAntennasManager as DAM} from "../../controller/models/classes";
import {useProjectState} from "../../Hooks/useProject";

const Div = styled.div`
  position: absolute;
  top: 65px;
  right: 10px;
  text-align: center;
`;

function MapLayerSwitch() {
  const RSRP = "RSRP - Coverage Optimized";
  const RSSI = "RSSI - Coverage Optimized";
  const {t} = useTranslation();
  const [predictionState, setPredictionState] = usePredictionState();
  const {displayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions();
  const [mapLayerSwitchState, setMapLayerSwitchState] = useMapLayerSwitchState();
  const timeoutRef = useRef();
  const {EM, samples, wifi} = Constants.mapLayerSwitchState;
  const [project, setProject] = useProjectState();
  const choices = [
    // {value: EM, label: t(`${EM}_map_layer_SC`), checked: mapLayerSwitchState[EM], disabled: false},
    {value: EM, label: t(`Hot Map`), checked: mapLayerSwitchState[EM], disabled: false},
    {value: samples, label: t("Cellular Map"), checked: !mapLayerSwitchState[EM], disabled: false},
    // {value: samples, label: t('samples_l'), checked: mapLayerSwitchState[samples]},
    {value: wifi, label: t(`${wifi} Map`), checked: mapLayerSwitchState[wifi], disabled: true},
  ];

  function toggleSelection(val) {
    return val === EM ? {EM: true, samples: false, wifi: false} : {EM: false, samples: true, wifi: false};
  }

  useEffect(() => {
    async function handleUseEffect() {
      if (mapLayerSwitchState.EM) {
        if (!(project.currentMapLayer === RSSI)) {
          const newProject = await Api.Project.editProject({...project, currentMapLayer: RSSI});
          const updatedProject = {...project, currentMapLayer: newProject.currentMapLayer};
          setProject(updatedProject);
        }
        const newDisplayedAntennas = DAM.setDisplayAll(displayedAntennas, true);
        updateDisplayedAntennas(newDisplayedAntennas);
      } else {
        if (!(project.currentMapLayer === RSRP)) {
          const newProject = await Api.Project.editProject({...project, currentMapLayer: RSRP});
          const updatedProject = {...project, currentMapLayer: newProject.currentMapLayer};
          setProject(updatedProject);
        }
        const newDisplayedAntennas = DAM.setDisplayAll(displayedAntennas, false);
        updateDisplayedAntennas(newDisplayedAntennas);
      }
    }
    handleUseEffect();
  }, [mapLayerSwitchState]);

  return (
    <Div>
      <ButtonsSelect
        values={choices}
        onSelect={(obj) => {
          const {value: val} = obj;
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            setMapLayerSwitchState(toggleSelection(val)); // Refactor
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }, 0);
        }}
      />
    </Div>
  );
}

export default MapLayerSwitch;
