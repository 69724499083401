import {
  ADD_PROJECT,
  MAP_LOADED,
  RESET_PROJECTS,
  SET_COVERAGE,
  SET_DISPLAYED_ANTENNAS,
  SET_HIGHLIGHT_OPACITY,
  SET_LINES,
  SET_LOCATION_STATS,
  SET_MAP_STATE,
  SET_MAP_TYPE,
  SET_MAP_ZOOM,
  SET_PREDICTION_STATE,
  SET_MAP_LAYER_SWITCH_STATE,
  SET_PROJECTS,
  SET_POLYGON_PATH,
  SET_TOTAL_COST,
  UPDATE_DISPLAYED_ANTENNAS,
  SET_USER_ENVIRONMENT,
  SET_TIME_OF_DAY,
  SET_AVERAGE_SIGNAL,
  SET_TECHNOLOGIES_COVERAGE,
  SET_SELECTED_TECHNOLOGY,
  SET_FILTER_STATE,
} from "../actionTypes";
import Constants, {userEnvironments, timeOfDay, defaultTechnologiesCoverage} from "../../controller/Constants";
import {DEFAULT_LOCATION_STATS} from "../defaultValues/map";
import {updateDisplayedAntennas} from "../actions/map";

export const DEFAULT_STATE = {
  mapType: Constants.mapTypes.BEST_SERVER,
  mapState: Constants.mapStates.SITES_COVERAGE,
  lines: false,
  coverage: 0,
  averageSignal: 0,
  technologiesCoverage: defaultTechnologiesCoverage,
  selectedTechnology: "4G",
  totalCost: 0,
  loaded: false,
  projects: [],
  currentProject: {}, //populated sites.
  displayedAntennas: [],
  mapZoom: 0,
  highlightOpacity: 0,
  predictionState: false,
  mapLayerSwitchState: {EM: false, samples: false, bestServer: false},
  userEnvironment: userEnvironments.MEN,
  timeOfDay: timeOfDay.AFTERNOON,
  polygonPath: [" "],
  locationStats: DEFAULT_LOCATION_STATS,
  filterState: {provider: "Cellcom", technology: "4G", date: "03-2022"},
};

const mapReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_LINES:
      return {...state, lines: action.linesFlag};
    case SET_COVERAGE:
      return {...state, coverage: Number(action.coverage).toFixed(2)};
    case SET_TOTAL_COST:
      return {...state, totalCost: Number(action.totalCost).toFixed(0)};
    case MAP_LOADED:
      return {...state, loaded: action.loaded != false};
    case SET_POLYGON_PATH:
      return {...state, polygonPath: action.polygonPath};
    case SET_PROJECTS:
      return {...state, projects: action.projects};
    case RESET_PROJECTS:
      return {...state, projects: []};
    case SET_MAP_TYPE:
      return {...state, mapType: action.mapType};
    case SET_MAP_STATE:
      return {...state, mapState: action.mapState};
    case SET_MAP_ZOOM:
      return {...state, mapZoom: action.mapZoom};
    case ADD_PROJECT:
      return {...state, projects: [...state.projects, action.project]};
    case SET_DISPLAYED_ANTENNAS:
      return {...state, displayedAntennas: action.displayedAntennas};
    case UPDATE_DISPLAYED_ANTENNAS:
      return {...state, displayedAntennas: updateDisplayedAntennas(state, action.displayedAntennas)};
    case SET_LOCATION_STATS:
      return {...state, locationStats: action.locationStats};
    case SET_HIGHLIGHT_OPACITY:
      return {...state, highlightOpacity: action.highlightOpacity};
    case SET_PREDICTION_STATE:
      return {...state, predictionState: action.predictionState};
    case SET_MAP_LAYER_SWITCH_STATE:
      return {...state, mapLayerSwitchState: action.mapLayerSwitchState};
    case SET_USER_ENVIRONMENT:
      return {...state, userEnvironment: action.userEnvironment};
    case SET_TIME_OF_DAY:
      return {...state, timeOfDay: action.timeOfDay};
    case SET_AVERAGE_SIGNAL:
      return {...state, averageSignal: action.averageSignal};
    case SET_TECHNOLOGIES_COVERAGE:
      return {...state, technologiesCoverage: action.technologiesCoverage};
    case SET_SELECTED_TECHNOLOGY:
      return {...state, selectedTechnology: action.selectedTechnology};
    case SET_FILTER_STATE:
      return {...state, filterState: action.filterState};
    default:
      return state;
  }
};

export default mapReducer;
