import Constants from "./Constants";
import {mapLayers} from "./Constants";
import {smartCity} from "./Constants"; ///////////////////////
const {getColor2, labelsColors} = smartCity; /////////////////////////////////////tyryhrtyhry6th

///////////////////////
// EMMapLayerValues = []
// signalMapLayerValues = []
// labelsColorsInfo = {
//     signalMapLayer: signalMapLayerValues,
//     EMMapLayer: EMMapLayerValues
// }

// $$$$$$$$$$$$$$$$$$$$$$$$$$$ start new colors here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function colorMapperNEW(rssi, mapLayerState, selectedTechnology) {
  // used for Bin and colored badge
  // rssiLevels ?????
  // console.log({rssi, selectedTechnology});
  // debugger;
  if (mapLayerState.EM) {
    // debugger;
    if (rssi > 1.12) return {color: "#ed2024", label: "Very High EM", starRate: 1}; // red
    if (between(rssi, 1.12, 0.00112)) return {color: "#fec00f", label: "High EM", starRate: 2}; // orange
    if (between(rssi, 0.00112, 0.0000112)) return {color: "#f6eb14", label: "Medium EM", starRate: 3}; // yellow
    if (between(rssi, 0.0000112, 0.000000112)) return {color: "#94c954", label: "Moderate EM", starRate: 4}; // light green
    if (between(rssi, 0.000000112, 0.00000000112)) return {color: "#00b14f", label: "Low EM", starRate: 5}; // green
    if (rssi < 0.00000000112) return {color: "#ffff", label: "Very Low EM", starRate: 5}; // green // "#939393"
  } else {
    if (selectedTechnology === "3G") {
      if (rssi > -70) return {color: "#00b14f", label: "High Coverage", starRate: 5}; // green
      if (between(rssi, -70, -80)) return {color: "#94c954", label: "Good Coverage", starRate: 4}; // light green
      if (between(rssi, -80, -90)) return {color: "#f6eb14", label: "Moderate. Coverage", starRate: 3}; // yellow
      if (between(rssi, -90, -100)) return {color: "#fec00f", label: "Low Coverage", starRate: 2}; // orange
      if (rssi < -100) return {color: "#ed2024", label: "Poor Coverage", starRate: 1}; // red
    } else {
      // selectedTechnology === "4G" or "5G"
      if (rssi > -80) return {color: "#00b14f", label: "High Coverage", starRate: 5}; // green
      if (between(rssi, -80, -90)) return {color: "#94c954", label: "Good Coverage", starRate: 4}; // light green
      if (between(rssi, -90, -100)) return {color: "#f6eb14", label: "Moderate. Coverage", starRate: 3}; // yellow
      if (between(rssi, -100, -110)) return {color: "#fec00f", label: "Low Coverage", starRate: 2}; // orange
      if (rssi < -110) return {color: "#ed2024", label: "Poor Coverage", starRate: 1}; // red
    }
  }
}

// $$$$$$$$$$$$$$$$$$$$$$$$$$$ end new colors here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

export const labelsColorsInfo = {
  signalMapLayer: [],
  EMMapLayer: {
    maxSignal: 0.01,
    minSignal: 4e-11,
    values: [
      {
        rssiValue: -20,
        label: " Very High EM",
        color: "#ed2024", //4287f5 blue//
        range: {
          max: 0.01,
          min: 0.0004,
        },
      },
      {
        rssiValue: -60,
        label: " High EM",
        color: "#fec00f", //  //0335fc
        range: {
          max: 4e-6,
          min: 4e-7,
        },
      },
      {
        rssiValue: -75,
        label: " Medium EM",
        color: "#f6eb14",
        range: {
          max: 4e-7,
          min: 4e-8,
        },
      },
      {
        rssiValue: -90,
        label: " Moderate EM",
        color: "#92d050", // blue = 0335fc
        range: {
          max: 4e-8,
          min: 4e-9,
        },
      },
      {
        rssiValue: -105,
        label: " Low EM",
        color: "#00b14f",
        range: {
          max: 4e-9,
          min: 4e-10,
        },
      },
      {
        rssiValue: -120,
        label: " Very Low EM",
        color: "#ffff",
        range: {
          max: 4e-10,
          min: 4e-11,
        },
      },
    ],
  },
};

export function getColor(mapLayer, rssi) {
  if (rssi > labelsColorsInfo[mapLayer].maxSignal) return "#fc03ec";
  if (rssi < labelsColorsInfo[mapLayer].minSignal) return "#ffffff";
  const color = labelsColorsInfo[mapLayer].find(({range}) => between(rssi, range.max, range.min)).color;
  return color;
}

export function getMaxSignal(mapLayer) {
  Math.min(labelsColorsInfo[mapLayer].values.map((value) => value.range.min));
}

function between(x, max, min) {
  return x >= min && x <= max;
}

export function colorMapper(rssi, rssiLevels, mapLayerState, selectedTechnology) {
  //used for color box
  if (mapLayerState.EM) {
    if (rssi > 1.12) return "#ed2024";
    if (between(rssi, 1.12, 0.00112)) return "#fec00f"; //"#7a5a07"
    if (between(rssi, 0.00112, 0.0000112)) return "#f6eb14";
    if (between(rssi, 0.0000112, 0.000000112)) return "#94c954";
    if (between(rssi, 0.000000112, 0.00000000112)) return "##00b14f";
    if (rssi < 0.00000000112) return "#ffffff"; // "#939393"
  } else {
    if (rssi > -80) return {color: "#00b14f", label: "High Coverage", starRate: 5}; // green
    if (between(rssi, -80, -90)) return {color: "#94c954", label: "Good Coverage", starRate: 4}; // light green
    if (between(rssi, -90, -100)) return {color: "#f6eb14", label: "Moderate. Coverage", starRate: 3}; // yellow
    if (between(rssi, -100, -110)) return {color: "#fec00f", label: "Low Coverage", starRate: 2}; // orange
    if (rssi < -110) return {color: "#ed2024", label: "Poor Coverage", starRate: 1}; // red
  }

  if (mapLayerState.EM) {
    if (between(rssi, 0.01, 0.0004)) return "#f70a65"; //"#7a5a07"
    if (between(rssi, 4e-6, 4e-7)) return "#ff0800";
    if (between(rssi, 4e-7, 4e-8)) return "#ffc019";
    if (between(rssi, 4e-8, 4e-9)) return "#21ea00";
    if (between(rssi, 4e-9, 4e-10)) return "#b3f266"; // "#5ecdff"
    if (between(rssi, 4e-10, 4e-11)) return "#ffffff"; // "#939393"
    if (rssi < 4e-11) return "#ffffff"; // "#939393"
    // if(between(rssi, 4e-10, 4e-11)) return "#ffffff" // "#939393"
  } else {
    if (between(rssi, -20, -60)) return "#7a5a07";
    if (between(rssi, -60, -75)) return "#ff0800";
    if (between(rssi, -75, -90)) return "#ffc019";
    if (between(rssi, -90, -105)) return "#21ea00";
    if (between(rssi, -105, -120)) return "#5ecdff";
    if (rssi < -120) return "#939393";
    // return '#de34eb'
  }

  // const is5 = rssiLevels.length === 5;          <<<<<<<<<
  // const oneIf5 = (is5 ? 1 : 0);                 <<<<<<<<<
  // if (rssi <= rssiLevels[3 + oneIf5])           <<<<<<<<<
  //     return '#939393';                         <<<<<<<<<
  // if (rssi <= rssiLevels[2 + oneIf5])           <<<<<<<<<
  //     return '#ff0800';                         <<<<<<<<<
  // if (rssi <= rssiLevels[1 + oneIf5])           <<<<<<<<<
  //     return '#ffc019';                         <<<<<<<<<
  // if (rssi <= rssiLevels[1])
  //     return '#fcff00';
  // if (rssi <= rssiLevels[0])
  //     return '#5ecdff';
  // return '#21ea00';
}

export function getRssiLevels(mapType, mapLayer, channelBW, minRssi = 0) {
  if (mapType === Constants.mapTypes.BEST_SERVER) {
    const layerType = getLayerType(mapLayer);
    if (layerType === "RSSI") return [-75, -83, -91, -99].map((num) => (channelBW === 60 ? num : num + 4)); // << ??
    if (layerType === "MCS" || layerType === "Bit_Rate") return [-75, -83, -87, -91, -99].map((num) => (channelBW === 60 ? num : num + 4));
  }
  if (mapType === Constants.mapTypes.C2I) return [15, 10, 6, 3];
  return [minRssi + 75, minRssi + 35, minRssi + 20, minRssi + 5, minRssi - 10, minRssi - 25];
}

export function calcRadiusMultByZoom(zoom) {
  const zoomLevels = [
    {biggerThen: 16, multiplier: 1},
    {biggerThen: 15, multiplier: 2},
    {biggerThen: 14, multiplier: 4},
    {biggerThen: 13, multiplier: 6},
    {biggerThen: 12, multiplier: 10},
  ].sort((a, b) => b.biggerThen - a.biggerThen);
  for (const {biggerThen, multiplier} of zoomLevels) {
    if (zoom >= biggerThen) return multiplier;
  }
  return 15;
}

export function extractAddressFromGeocoder(results, typeWanted = "route") {
  if (typeWanted === "route") {
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      let isRoute = false;
      result.types.forEach((type) => {
        if (type === "route") {
          isRoute = true;
        }
      });
      if (isRoute) return result.formatted_address;
    }
  } else if (typeWanted === "notRoute") {
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      let isRoute = false;
      result.types.forEach((type) => {
        if (type === "route") {
          isRoute = true;
        }
      });
      if (!isRoute) return result.formatted_address;
    }
  }
  return "";
}

export function mapProjectBinsPopulated(data) {
  return {
    ...data,
    sites: data.sites.map((site) => ({
      ...site,
      heights: site.preDesign.sectors.map((height) => ({
        ...height,
        binsPlacement: undefined,
        bins: height.binsPlacement.bins,
      })),
    })),
  };
}

export function toObjectWithoutProperties(obj, keys) {
  let target = {};
  for (const [key, val] of Object.entries(obj)) {
    if (keys.indexOf(key) >= 0) continue;
    target[key] = val;
  }
  return target;
}

export function latLngToPoint(latLngObj, bounds, zoom, map) {
  const {google} = window;
  const latLng = typeof latLngObj.lat === "function" ? latLngObj : new google.maps.LatLng(latLngObj);
  const topRight = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
  const bottomLeft = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());
  const scale = Math.pow(2, zoom);
  const worldPoint = map.getProjection().fromLatLngToPoint(latLng);
  return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
}

export function pointToLatLng(point, bounds, zoom, map) {
  const {google} = window;
  // const point = new google.maps.Point(pointVal);
  const topRight = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
  const bottomLeft = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());
  const scale = Math.pow(2, zoom);
  const worldPoint = new google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
  return map.getProjection().fromPointToLatLng(worldPoint);
}

export function antennaTypeToGain(antennaType) {
  const mapper = {
    TYPE_1X1: 9.0,
    TYPE_2X2: 9.0,
    TYPE_4X4: 12.0,
    TYPE_8X8: 15.5,
    TYPE_8X8_STEERED: 20.7,
    TYPE_16X16: 18.0,
    TYPE_32X32: 21.0,
    TYPE_64X64_STEERED: 24.0,
  };
  return mapper[antennaType];
}

export function getLayerType(mapLayer) {
  const mapLayerMapper = {
    "RSSI - Coverage Optimized": "RSSI",
    "RSSI - Capacity Optimized": "RSSI",
    "MCS - Coverage Optimized": "MCS",
    "MCS - Capacity Optimized": "MCS",
    "Bit Rate - Coverage Optimized": "Bit_Rate",
    "Bit Rate - Capacity Optimized": "Bit_Rate",
    "RSRQ - Coverage Optimized": "RSRQ",
    "RSRQ - Capacity Optimized": "RSRQ",
    "RSRP - Coverage Optimized": "RSRP",
    "RSRP - Capacity Optimized": "RSRP",
  };
  return mapLayerMapper[mapLayer];
}

export function getLayerIndicator(mapLayer) {
  if (mapLayer.toLowerCase().includes("coverage")) return "coverage";
  if (mapLayer.toLowerCase().includes("capacity")) return "capacity";
  throw Error("Wrong Map Layer Value");
}

export function mapBitRateMult(smartType) {
  const mapBitRateMultiplier = {
    TYPE_1X1: 1,
    TYPE_2X2: 1,
    TYPE_4X4: 1,
    TYPE_8X8: 2,
    TYPE_8X8_STEERED: 2,
    TYPE_16X16: 4,
    TYPE_32X32: 8,
    TYPE_64X64_STEERED: 16,
  };
  return mapBitRateMultiplier[smartType];
}

export function rssiToBitRate(rssi, {smartType, channelBW}) {
  const multiplier = Boolean(smartType) ? mapBitRateMult(smartType) : 1;
  const {mapTypes} = Constants;
  const rssiLevels = getRssiLevels(mapTypes.BEST_SERVER, mapLayers.BIT_RATE_CAPACITY, channelBW);
  const rssiIndex = rssiLevels.findIndex((val) => val <= rssi);
  const index = rssiIndex === -1 ? 5 /*rssiLevels.length*/ : rssiIndex;
  const bitRateLevels = channelBW === 20 ? [54, 29.4, 18.6, 10, 2.3, 0] : [162, 88.3, 55.8, 30, 6.8, 0];
  return bitRateLevels[index] * multiplier;
}

export function rssiToBitRateString(rssi, smartType, channelBW) {
  return rssiToBitRate(rssi, {smartType, channelBW}) + " Mbps";
}

export function locationToAddress(location) {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({location: {lat: Number(location.lat), lng: Number(location.lng)}}, (results, status) =>
      status === "OK" ? resolve(results) : reject(status)
    );
  });
}

export function getBitRateFromBin(signal, sites, projectParams) {
  const reduceToHighestRssi = (acc, obj) => (acc.signal > obj.signal ? acc : obj);
  const bestServerObj = sites.reduce(reduceToHighestRssi, {
    signal: -Infinity,
  });
  return rssiToBitRateString(signal, bestServerObj.smartType, projectParams.channelBW);
}

export function reduceBinSitesToHighestRssi(acc, obj) {
  return acc.signal > obj.signal ? acc : obj;
}

export function mapLayerToSignalType(mapLayer) {
  const mapper = {
    "": "",
  };
}

export function getSectorLabel(sector) {
  return `${sector.height}m${sector.azimuth ? " " + sector.azimuth + "°" : " Omni"}${sector.number ? " #" + sector.number : ""}`;
}
