import {useCallback, useEffect, useRef, useState} from "react";
import {openDialog} from "../store/actionCreators/general";
import Constants from "../controller/Constants";
import {modals, events} from "../controller/Constants";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {UserApi} from "../controller/ApiManager/index";
import jwt from "jsonwebtoken";
import EventEmitter from "../controller/EventEmitter";
import {mapLoadedCreator} from "../store/actionCreators/map";
import {useSetUserRedux} from "./common"
import { string } from "prop-types";

export function isIdTokenExpired(idToken) {
    if (!Boolean(idToken)) {
        throw Error(`"${idToken}" is not a valif id token`);
    }
    const tokenDecoded = decodeJwtToken(idToken);
    const {exp} = tokenDecoded;
    const expirationDate = new Date(exp * 1000);
    const now = new Date(Date.now());
    const isExpired = Boolean(now.getTime() >= expirationDate.getTime());
    return isExpired;
}

function useIsAuthenticated(setError, setFetching, setFetched) {
    const userRedux = useSelector(state => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        if (userRedux.isAuthenticated) {
            setError(null);
            setFetching(false);
            setFetched(true);
        } else {
            dispatch(mapLoadedCreator(false));
        }
    }, [userRedux.isAuthenticated]);
}

function useFetchRefreshToken(setFetching, fetchingRef, setError, setFetched) {
    const dispatch = useDispatch();
    const setUserRedux = useSetUserRedux();
    const openLoginDialog = useCallback(() => {
        return dispatch(openDialog(modals.LOGIN));
    }, [dispatch]);
    return useCallback(async function (email, refreshToken) {
        let idToken;
        try {
            setFetching(true);
            fetchingRef.current = true;
            const tokens = await UserApi.refreshToken({email, refreshToken});
            const {refreshToken: refreshRes} = tokens;
            idToken = tokens.idToken;
            if (typeof idToken !== 'string' || typeof refreshRes !== 'string') {
                console.log('token: ', tokens);
                throw Error('result from refresh token arent string!');
            }
            const decodedToken = decodeJwtToken(idToken);
            const {email: userEmail, 'cognito:groups': groups} = decodedToken;
            setUserRedux({user: {email: userEmail, groups}, idToken, refreshToken: refreshRes});
            setError(null);
        } catch (error) {
            console.error(error);
            setError(error);
            openLoginDialog();
        } finally {
            fetchingRef.current = false;
            setFetching(false);
            setFetched(true);
        }
    }, [setUserRedux, openLoginDialog]);
}

function useCheckRefreshToken(setFetching, setFetched, fetchRefreshToken, setError) {
    const dispatch = useDispatch();
    const setUserRedux = useSetUserRedux();
    const location = useLocation();
    const openLoginDialog = useCallback(() => {
        return dispatch(openDialog(modals.LOGIN));
    }, [dispatch]);
    useEffect(() => {
        const idTokenStored = localStorage.getItem(Constants.localStorage.ID_TOKEN);
        const refreshToken = localStorage.getItem(Constants.localStorage.REFRESH_TOKEN);
        if (Boolean(idTokenStored)) {
            const tokenDecoded = decodeJwtToken(idTokenStored);  
            const {email, 'cognito:groups': groups} = tokenDecoded;
            const isExpired = isIdTokenExpired(idTokenStored);
            if (!isExpired) {
                setUserRedux({user: {email, groups}, idToken: idTokenStored, refreshToken});
                setFetching(false);
                setFetched(true);
            } else if (isExpired && Boolean(idTokenStored) && Boolean(refreshToken)) {
                fetchRefreshToken(email, refreshToken);
            }
        } else {
            openLoginDialog();
            setFetching(false);
            setFetched(true);
            setError(Error('require login'));
        }
    }, [location.pathname, fetchRefreshToken, setUserRedux, openLoginDialog]);
}

function useFetchRefreshTokenEvent(fetchingRef, fetchRefreshToken) {
    useEffect(() => {
        const eventId = EventEmitter.subscribe(events.REFRESH_USER_TOKEN, async (data) => {
            const refreshToken = localStorage.getItem(Constants.localStorage.REFRESH_TOKEN);
            const idToken = localStorage.getItem(Constants.localStorage.ID_TOKEN);
            const decodedToken = decodeJwtToken(idToken);
            const {email} = decodedToken;
            if (refreshToken && !fetchingRef.current) {
                await new Promise((resolve, reject) => setTimeout(() => fetchRefreshToken(email, refreshToken).then(resolve).catch(reject), 10));
            }
            if (fetchingRef.current) {
            }
        });
        return () => {
            EventEmitter.unsubscribe(events.REFRESH_USER_TOKEN, eventId);
        };
    }, [fetchRefreshToken]);
}

function decodeJwtToken(token) {
    return token.startsWith("Bearer ")? jwt.decode(token.substring(7)): jwt.decode(token);
 }

export function useAuth() {
    const [error, setError] = useState(null);
    const [fetched, setFetched] = useState(false);
    const [fetching, setFetching] = useState(false);
    const fetchingRef = useRef(false);

    useIsAuthenticated(setError, setFetching, setFetched);
    const fetchRefreshToken = useFetchRefreshToken(setFetching, fetchingRef, setError, setFetched);
    useCheckRefreshToken(setFetching, setFetched, fetchRefreshToken, setError);
    useFetchRefreshTokenEvent(fetchingRef, fetchRefreshToken);

    return {error, fetched, fetching}
}