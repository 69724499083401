import React from "react";
import PropTypes from "prop-types";
import SubDropdown from "./SubDropdown";
import styled from "styled-components";

const Li = styled.li`
  cursor: pointer;
  &&&&&& {
    /* cursor: ${({disabled}) => (disabled ? "default" : "pointer")}; */
    /* color: red;
    background-color: #d8dbde; */

    &.disabled {
      // #page-header .primary-nav ul li span
      background-color: #f2f2f2;
      cursor: "default";
      color: #1f1f1f;
      //////////////////

      a:hover span,
      a span {
        cursor: "default";
        color: #1f1f1f;
        background-color: #f2f2f2;
      }
    }
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
function DropdownItem({children, onClick, title, icon, content, disabled}) {
  // eslint-disable-next-line
  return (
    <Li disabled className={`${children && "has-child"} ${disabled && "disabled"}`}>
      <a onClick={disabled ? () => ({}) : onClick}>
        <FlexDiv>
          <div>
            {icon}
            {title}
          </div>
          {content}
        </FlexDiv>
      </a>
      {children && <SubDropdown>{children}</SubDropdown>}
    </Li>
  );
}

DropdownItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default DropdownItem;
