import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import BackButton from "../StyledComponents/BackButton";
import SiteDetails from "../Site/SiteDetails";
import SectorListEditor from "../Sector/SectorListEditor";
import Button from "react-bootstrap/Button";
import Api from "../../controller/ApiManager/index";
import {closeDialog,} from "../../store/actionCreators/general";
import {useProjectState} from "../../Hooks/useProject";
import Alert from "react-bootstrap/Alert";
import Constants from "../../controller/Constants";
import {modals} from "../../controller/Constants";

import {siteDetailsEqual} from "../../controller/dataOperations";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import {useFetchCallback} from "../../Hooks/useFetchCallback";
import {useGlobalStateOperations} from "../../Hooks/useGlobalOperations";
import {updateSector} from "../../controller/asyncOperations";
import {openDialog, setDialogSite} from "../../store/actionCreators/general";
import {useDialogAlert} from "../../contexts/DialogAlert";


const ModalContent = styled.div`
  width: 765px;
  .select-button-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .select-button {
      width: 100%;
    }
  }
  .save-changes-button {
    margin: 20px auto;
    &.btn {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  .full-width{
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
  }
`;
const DEFAULT_ALERT = {message: "", error: false};
// const DEFAULT_SITE = {
//     displayName: "",
//     location: {lat: NaN, lng: NaN,},
//     address: "",
//     siteNumber: NaN,
//     siteName: "",
//     area: "",
// };
const  {EDIT_PROJECT} = modals;
const {siteModes: {predesign, postdesign},} = Constants;

function EditSite({closeDialog, setDialogSite, onBack, openDialog}) {
    const dialogSite = useSelector((state) => state.general.dialogSite);
    const [project, setProject] = useProjectState();
    const site = project.sites.find(({_id}) => _id === dialogSite._id);
    const [siteState, setSiteState] = useState(site);
    const allSectors = [...site.preDesign.sectors, ...site.postDesign.sectors];
    const [alert, setAlert] = useDialogAlert();
    const {t} = useTranslation();
    const [mode, setMode] = useState(site.siteMode);
    const saveSiteChanges = useFetchCallback(async () => {
            if (siteDetailsEqual(site, siteState))
                return;
            const updatedSite = await Api.Site.editSite(siteState);
            if (updatedSite === null) return;
            setAlert({message: `Site ${updatedSite.displayName} updated successfully`,});
            setDialogSite({...updatedSite});
            setSiteState(updatedSite);
            setProject({
                ...project,
                sites: project.sites.map((siteI) =>
                    siteI._id === updatedSite._id ? {
                        ...updatedSite,
                        preDesign: siteI.preDesign,
                        postDesign: siteI.preDesign
                    } : siteI
                ),
            });
        }, [site, siteState, project, setProject, setSiteState, setAlert,],
        {setError: err => setAlert({error: true, message: "there were problem updating the site.",})}
    );

    const deleteSite = useFetchCallback(async () => {
            const toDelete = window.confirm(t("Are_You_Sure_Site_Delete"));
            if (!toDelete) return;
            await Api.Site.deleteSite(site._id, project._id);
            setProject({
                ...project,
                sites: project.sites.filter((siteI) => siteI._id !== dialogSite._id),
            });
            openDialog(EDIT_PROJECT);
        }, [site, project, setProject, openDialog, setAlert],
        {setError: err => setAlert({message: "Error Deleting Site, please see Logs...", error: true})}
    );


    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t("Edit_Site_Title")}</h2>
                    {typeof onBack === "function" && (
                        <BackButton
                            className="btn btn-outline-light btn-rounded"
                            onClick={onBack}
                        />
                    )}
                </div>
            </Modal.Header>
            <Modal.Body>
                {alert.message && (
                    <Alert
                        dismissible
                        onClose={() => setAlert(DEFAULT_ALERT)}
                        variant={alert.error ? "danger" : "success"}
                    >
                        {alert.message}
                    </Alert>
                )}
                <SiteDetails
                    onDelete={deleteSite}
                    onSiteChange={setSiteState}
                    site={siteState}
                />
                <div className={'full-width'}>
                    <Button className={"save-changes-button"} onClick={saveSiteChanges}>
                        {t("Save_Site_Changes") + "   "}
                    </Button>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <ButtonsSelect
                            type={"radio"}
                            values={[
                                {value: predesign,  label: t("Pre_Design"),     checked: mode === predesign},
                                {value: postdesign, label: t("Post_Design"),    checked: mode === postdesign},]}
                            onSelect={({value}) => setMode(value)}
                        />
                    </div>
                    <div className={"col-sm-12"}>
                        <SectorListEditor
                            site={site}
                            changeSite={setSiteState}
                            siteMode={mode}
                            sectors={allSectors}
                        />
                    </div>
                </div>
            </Modal.Body>
        </ModalContent>
    );
}

export default connect(() => ({}), {setDialogSite, openDialog, closeDialog})(EditSite);