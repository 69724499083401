import React, {useCallback, useRef} from "react";
import {useLocationStats, useRssiColor} from "../../Hooks/map";
import {Marker, useGoogleMap} from "@react-google-maps/api";
import EventEmitter from "../../controller/EventEmitter";
import {events} from "../../controller/Constants";
import {locationToAddress, extractAddressFromGeocoder, reduceBinSitesToHighestRssi, colorMapperNEW} from "../../controller/mapper";
import {OverlayView, Polyline} from "@react-google-maps/api";
import {ListGroup, Popover} from "react-bootstrap";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import StarsBarInfo from "../Common/StarsBarInfo";
import {useAverageSignal, useMapLayerSwitchState} from "../../Hooks/common";

const StyledPopover = styled(Popover)`
  &#location-marker-popover {
    z-index: 4;
    border-color: ${({theme}) => theme.palette.secondary.main};
    top: -65px;
    left: 15px;
    width: auto;
    .arrow {
      margin: 55px 0;
      &::before {
        border-right-color: ${({theme}) => theme.palette.secondary.main};
      }
    }
    .list-group-item {
      white-space: nowrap;
    }
  }
`;
const {SET_LOCATION_STATS} = events;

function LocationMarker({}) {
  const {t} = useTranslation();
  const locationStats = useLocationStats();
  const {closestBin, rssi, bitRate, distance, location} = locationStats;
  const map = useGoogleMap();
  const markerRef = useRef(null);
  const color = useRssiColor(rssi);
  const [mapLayerSwitchState] = useMapLayerSwitchState();
  const averageSignal = useAverageSignal();
  const {color: badgeColor, label: badgeLabel, starRate} = getColoredLabels(averageSignal);
  function getColoredLabels(signal) {
    const ans = colorMapperNEW(signal, mapLayerSwitchState);
    return ans || {cplor: "#ffff", label: ""}; // TUDO: Refactor
  }
  const handleOnDragEnd = useCallback(() => {
    const location = markerRef.current.getPosition().toJSON();
    console.log({location});
    locationToAddress(location)
      .then((results) => {
        EventEmitter.dispatch(SET_LOCATION_STATS, {address: extractAddressFromGeocoder(results), location});
      })
      .catch((status) => {
        console.log({status});
      });
  }, []);
  const fixedNumber = (num, fixed) => Number.parseFloat(num).toFixed(fixed || 2);
  const tableData = [
    {key: "Distance_", value: distance + " " + t("Meters_LM")},
    // {
    //   key: "Rate",
    //   value: bitRate ? fixedNumber(bitRate.split(" ")[0]) + " " + bitRate.split(" ")[1] : fixedNumber(closestBin?.signal) + " Dbm",
    // },
  ];
  return (
    locationStats.display && (
      <>
        <Marker
          onLoad={(marker) => {
            markerRef.current = marker;
          }}
          draggable
          onDragEnd={handleOnDragEnd}
          map={map}
          position={location}
        />
        <OverlayView position={location} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <StyledPopover id={"location-marker-popover"}>
            <Popover.Title as="h3">{t("Bin_Popover_Title")}</Popover.Title>
            <ListGroup variant="flush">
              {tableData.map(({key, value}) => (
                <ListGroup.Item key={key}>
                  {key} : {value}{" "}
                </ListGroup.Item>
              ))}
              <ListGroup.Item>
                {/* {t("Rate") + " "} */}
                <StarsBarInfo starRate={starRate} badgeColor={badgeColor} badgeLabel={badgeLabel} />
              </ListGroup.Item>
            </ListGroup>
          </StyledPopover>
        </OverlayView>
        <Polyline
          path={[closestBin.location, location]}
          options={{
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: color,
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 30000,
            zIndex: 1000 + 100,
          }}
        />
      </>
    )
  );
}

export default LocationMarker;
