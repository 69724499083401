const offline_server_port = 3010;
let urls;
if (process.env.REACT_APP_STAGE === "prod") {
  urls = {
    TRANSLATION_URL: "https://pjp3f7szhl.execute-api.eu-west-1.amazonaws.com/prod/translations",
    AUTH_URL: "https://1i1mu3cmp3.execute-api.eu-west-1.amazonaws.com/prod/",
    REST_URL: "https://5ivcds5uqi.execute-api.eu-west-1.amazonaws.com/prod/",
  };
} else if (process.env.REACT_APP_STAGE === "dev") {
  urls = {
    TRANSLATION_URL: "https://pjp3f7szhl.execute-api.eu-west-1.amazonaws.com/prod/translations",
    AUTH_URL: "https://riph7qauh9.execute-api.eu-west-1.amazonaws.com/dev/",
    REST_URL: "https://vsqbc3asnj.execute-api.eu-west-1.amazonaws.com/dev/",
  };
} else if (process.env.REACT_APP_STAGE === "local") {
  throw Error("Not implemented!");
  urls = {
    TRANSLATION_URL: "", // 'https://pjp3f7szhl.execute-api.eu-west-1.amazonaws.com/prod/translations',
    AUTH_URL: "", // 'https://30f7cwho3i.execute-api.eu-west-1.amazonaws.com/dev/',
    REST_URL: "", // `http://localhost:${offline_server_port}/dev/`,
  };
} else if (process.env.REACT_APP_STAGE === "staging") {
  throw Error("Not implemented!");
  urls = {
    TRANSLATION_URL: "", // 'https://pjp3f7szhl.execute-api.eu-west-1.amazonaws.com/prod/translations',
    AUTH_URL: "", //'https://huzmnkok55.execute-api.eu-west-1.amazonaws.com/stage/',
    REST_URL: "", // `https://xn7s5ai608.execute-api.eu-west-1.amazonaws.com/stage/`,
  };
}
export default urls;
