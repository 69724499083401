import {useEffect, useMemo, useState} from "react";
import {SET_COVERAGE, SET_AVERAGE_SIGNAL} from "../store/actionTypes";
import {getSiteSectors, isPointInPolygon} from "../controller/dataOperations";
import useDisplayMapDetails from "./useDisplayMapDetails";
import {openDialog} from "../store/actionCreators/general";
import Constants, {defaultTechnologiesCoverage} from "../controller/Constants";
import {techUsageCategoriesThresholds as categoriesThresholds, techUsageCategories as usageCategories} from "../controller/Constants";
import useProject, {useProjectParams} from "./useProject";
import {rssiToBitRate} from "../controller/mapper";
import {
  useFetchLoader,
  useFilterStateActins,
  usePredictionState,
  usePrevious,
  useSelectedTechnology,
  useSetTechnologiesCoverage,
} from "./common";
import {useDispatch, useSelector} from "react-redux";
import {useCorrectionFactorCalculator} from "./useCorrectionFactor";
import GrahamScan from "@lucio/graham-scan";
import useMapPrediction from "./useMapPrediction";
import {ifFuncExec} from "../controller/common";
import {useDisplayedAntennasActions} from "./displayedSectors";
import {useMapLayerSwitchState} from "../Hooks/common";
import {DisplayedAntennasManager as DAM} from "../controller/models/classes";
import {useLocation} from "react-router-dom";
import {useUserEnvironment} from "./useUserEnvironment";

const {
  mapStates: {AREA_SELECTION},
} = Constants;

const isGraham = false;

function checkNotifyClustering(chosenSites, displayedSectors, project, binsArray, loadingPrediction) {
  const activeDisplayedSectors = displayedSectors.filter((displayedSector) => displayedSector.display);
  const isChosenSites = Boolean(chosenSites);
  const minimumSites = isChosenSites ? 2 : 1;
  const selectedSites = activeDisplayedSectors.map((obj) => obj.siteId);
  const sitesExist = selectedSites.map((siteId) => Boolean(project.sites.find((site) => siteId === site._id))).every((_) => _);
  const isSitesExistAndEnough = selectedSites.length >= minimumSites ? sitesExist : false;
  const shouldNotifyClustering =
    isSitesExistAndEnough &&
    activeDisplayedSectors.filter((displayedSector) => displayedSector.sectorId).length > 0 &&
    binsArray.length === 0 &&
    !loadingPrediction;
  return shouldNotifyClustering;
}

function isHasBinsPlacements(project) {
  const allSectors = project.sites.flatMap(getSiteSectors);
  return allSectors.find((sector) => sector.binsPlacements.length > 0)?.binsPlacements;
}

function useMapContentEffects({map, polygonPath, binsMapper, sites: stateSites, chosenSites}) {
  const userEnvironment = useUserEnvironment();
  const setTechnologiesCoverage = useSetTechnologiesCoverage();
  const calcCorrectionFactor = useCorrectionFactorCalculator();
  const [filterState, setFilterState] = useFilterStateActins();

  const project = useProject();
  const sites = project.sites.filter((siteI) => stateSites.some((sSite) => sSite._id === siteI._id));
  const dispatch = useDispatch();
  const isAreaSelection = useSelector((state) => state.map.mapState) === AREA_SELECTION;
  const {displayedAntennas, resetDisplayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions();
  const projectParams = useProjectParams();
  const {rssiThreshold, mapLayer, channelBW} = projectParams;
  const isBitRate = ["Bit Rate - Coverage Optimized", "Bit Rate - Capacity Optimized"].includes(mapLayer);
  const [mapLayerSwitchState, setMapLayerSwitchState] = useMapLayerSwitchState();
  const [isFetchLoader] = useFetchLoader();
  const [binsArray, setBinsArray] = useState(mapBins);
  const location = useLocation();
  const [predictionState, setPredictionState] = usePredictionState();
  const selectedTechnology = useSelectedTechnology();
 
  useEffect(() => {
    map.setOptions({draggable: !isAreaSelection});
  }, [isAreaSelection]);

  useEffect(() => {
    // when mapLayerSwitchState changes to bestServer, this useEffect updates the displayedAntennas display prop
    //toto
    if (mapLayerSwitchState.bestServer) return handleBestServerDisplayedAntennas();
    if (mapLayerSwitchState.EM || mapLayerSwitchState.samples) return initDisplayedAntennasDisplay();
  }, [filterState, mapLayerSwitchState]);

  function filterBins(bins) {
    if (isGraham && predictionState) {
      const points = mapBins(2).map(({location}) => [location.lat, location.lng]);
      const grahamScan = new GrahamScan();
      grahamScan.setPoints(points);
      const polygon = grahamScan.getHull();
      bins = bins.filter((bin) => isPointInPolygon([bin.location.lat, bin.location.lng], polygon));
    }
    if (polygonPath.length > 2) {
      const polygonPathFlat = polygonPath.map((point) => [point.lat, point.lng].map(ifFuncExec));
      bins = bins.filter((bin) => isPointInPolygon([bin.location.lat, bin.location.lng], polygonPathFlat));
    }
    return bins;
  }

  function mapBins(debuggArg) {
    const bins = binsMapper(sites, displayedAntennas, calcCorrectionFactor, mapLayerSwitchState);
    return bins;
  }

  const data = useMapPrediction({setBinsArray, sites, mapBins});

  const filteredBins = useMemo(() => filterBins(binsArray), [binsArray, predictionState, polygonPath, userEnvironment]); //  userEnvironment + ?


  const mapStats = useDisplayMapDetails({binsArray: filteredBins});

  useEffect(() => {
    // calc averageSignal when sectors changed
    function calcAverageSignalObject(accm, binObject) {
      accm.binsCount += binObject.sites.length;
      accm.signalsSum += binObject.sites.map(({signal}) => signal).sum();
      return accm;
    }
    function calcAverageSignalObjectEM(accm, binObject) {
      accm.binsCount += 1;
      accm.signalsSum += binObject.signal;
      return accm;
    }
    const reducer = mapLayerSwitchState.EM ? calcAverageSignalObjectEM : calcAverageSignalObject;
    const avgObject = filteredBins.reduce(reducer, {binsCount: 0, signalsSum: 0});
    let averageSignal = avgObject.signalsSum / avgObject.binsCount;
    averageSignal = mapLayerSwitchState.EM ? Number(averageSignal) : Number(averageSignal.toFixed(0));
    // console.log({averageSignal});
    // if(mapLayerSwitchState.EM)/////////////////////////////////////
    dispatch({type: SET_AVERAGE_SIGNAL, averageSignal});
  }, [project, userEnvironment, displayedAntennas, mapLayer, filteredBins, polygonPath, mapLayerSwitchState]); //  userEnvironment + ?

  useEffect(() => {
    // calc categories thresholds when sectors changed
    function calcCoverageForCategorys(accm, binObject) {
      for (const [category, threshold] of Object.entries(categoriesThresholds[selectedTechnology])) {
        // const lowerCasekey = category.toLocaleLowerCase().replace("_", " ");
        // const key = lowerCasekey.charAt(0).toUpperCase() + lowerCasekey.slice(1);
        if (binObject.signal >= threshold) accm[category] += 1;
      }
      return accm;
    }
    // console.log("calc categories thresholds");
    if (binsArray.length === 0) return;
    const techThresholds = filteredBins.reduce(calcCoverageForCategorys, {...defaultTechnologiesCoverage});
    const technologiesCoverage = {...techThresholds};
    for (const [category, value] of Object.entries(technologiesCoverage)) {
      technologiesCoverage[category] = Number(((value / filteredBins.length) * 100).toFixed());
    }
    setTechnologiesCoverage(technologiesCoverage);
  }, [project, displayedAntennas, mapLayer, filteredBins, polygonPath]);

  useEffect(() => {
    // calc coverage when sectors changed
    function calcCoverageForBin(accm, binObject) {
      if (!isBitRate && binObject.signal >= rssiThreshold) {
        return accm + 1;
      }
      if (!isBitRate) return accm;
      const {smartType} = binObject.sites.reduce((acc, b) => (acc.signal > b.signal ? acc : b));
      if (isBitRate && rssiToBitRate(binObject.signal, {smartType, channelBW}) >= rssiThreshold) {
        return accm + 1;
      }
      return accm;
    }

    const thresholdBins = filteredBins.reduce(calcCoverageForBin, 0);
    const coverage = (thresholdBins / filteredBins.length) * 100;
    dispatch({type: SET_COVERAGE, coverage});
  }, [project, userEnvironment, displayedAntennas, mapLayer, rssiThreshold, channelBW, filteredBins, polygonPath]);

  useEffect(() => {
    // update bins when mapLayer changes
    const someBinsPlacements = isHasBinsPlacements(project);
    if (!someBinsPlacements) {
      resetDisplayedAntennas();
      return;
    }
    const placementSameAsMapLayer = someBinsPlacements && someBinsPlacements[0].mapLayer === project.currentMapLayer;
    if (!placementSameAsMapLayer) return;
    const bins = mapBins(1);
    setBinsArray(bins);
  }, [location.pathname]);

  useEffect(() => {
    // if no bins - clear ...
    if (binsArray.length === 0) setTechnologiesCoverage({...defaultTechnologiesCoverage});
  }, [binsArray]);

  function handleBestServerDisplayedAntennas() {
  
    const sites = project.sites.filter((site) => site.displayName.split(",")[1] === filterState.date);
    const sectors = sites.flatMap((site) =>
      [...site.preDesign.sectors, ...site.postDesign.sectors].filter((sector) => filterState.technology.startsWith(sector.height))
    );
    const sectorsIds = sectors.map((sector) => sector._id);
    const displayedAntenna = displayedAntennas.filter((antenna) => sectorsIds.includes(antenna.sectorId));
    // setdisplatall

    console.log({displayedAntennas, displayedAntenna, sectorsIds});
    // const tetst = DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds);
    console.log("-----------------updateDisplayedAntennas 1");
    updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds));
    return;
  }

  

  useEffect(() => {// when ptoject loads' selected mapLayerSwitchState shuld be corisponding to the projectParams.currentMapLayer
   let newMapLayerSwitchState = {}
    if(mapLayer === "RSRP - Coverage Optimized"){
      newMapLayerSwitchState = {...mapLayerSwitchState, EM: false, samples: true, bestServer: false}
    }else if(mapLayer === "RSSI - Coverage Optimized"){
      newMapLayerSwitchState = {...mapLayerSwitchState, EM: true, samples: false, bestServer: false}

    }else{
      throw Error("Only sepport mapLayers 'RSRP - Coverage Optimized' or 'RSSI - Coverage Optimized' at the momment");
    }
        setMapLayerSwitchState(newMapLayerSwitchState);
  }, [ projectParams ]);



  function initDisplayedAntennasDisplay() {
    
    // const sites = project.sites.filter((site) => site.displayName.split(",")[0] === "Cellcom");
    // const site = sites.find((site) => site.displayName.split(",")[1] === "4G");
    const sites = project.sites.filter((site) => site.displayName.split(",")[0] === filterState.provider);
    const site = sites.find((site) => site.displayName.split(",")[1] === filterState.date);
    if (!site) {
      updateDisplayedAntennas(DAM.setDisplayByIds(displayedAntennas, []));
      return;
    }
    const sectors = [...site.preDesign.sectors, ...site.postDesign.sectors].filter((sector) => filterState.technology.startsWith(sector.height));
    const sectorsIds = sectors.map((sector) => sector._id);
    const sectorsIds4G = sectors.filter((sector) => sector.height === "4");
    const displayedAntenna = displayedAntennas.filter((antenna) => sectorsIds4G.includes(antenna.sectorId));
    updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, displayedAntenna, sectorsIds));
  }

  useEffect(() => {
    // bins check to notify user about clustering. and open the 'rearrange bins' dialog
    const timeout = setTimeout(() => {
      const shouldNotifyClustering = checkNotifyClustering(chosenSites, displayedAntennas, project, binsArray, isFetchLoader);
      if (shouldNotifyClustering) dispatch(openDialog(Constants.modals.CLUSTER_ALERT));
    }, 200);
    return () => clearTimeout(timeout);
  }, [project, userEnvironment, displayedAntennas, binsArray, project.currentMapLayer]);

  return {...mapStats, binsArray: filteredBins, loadingPrediction: isFetchLoader};
}

export default useMapContentEffects;
