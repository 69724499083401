import React from "react";
import {useState} from "react";
import styled, {css} from "styled-components";
import {keyframes} from "styled-components";
import useProject from "../../Hooks/useProject";

// const borderSize = '0.25em';
const borderSize = 0.25;
const borderColor = "#C20A0A";
const alarmAnimation = keyframes`
0%   { box-shadow: 0 0 0 ${borderSize * 0.8}em ${borderColor}; }
25%  { box-shadow: 0 0 0 ${borderSize * 0.2}em ${borderColor}; }
50%  { box-shadow: 0 0 0 ${borderSize * 1.5}em ${borderColor}; }
75%  { box-shadow: 0 0 0 ${borderSize * 0.8}em ${borderColor}; }
100% { box-shadow: 0 0 0 ${borderSize * 1.2}em ${borderColor}; }
`;
// width: ${props => props.iconSize};
// height: ${props => props.iconSize};
// animation: ${props => (props.animating ? css`${fadeInSlide} 1s ease-in-out infinite forwards;` : '')} ;
// ${({ active }) => active && `
//     animation: ${alarmAnimation};
//     animation-duration: 1s;
//     animation-iteration-count: infinite;
//   `}
const StyledAlarmIcon = styled.div`
  border-radius: 50%;
  background: ${(props) => (props.active ? "#f53d3d" : "#D2D0CF")};
  width: ${(props) => props.iconSize};
  height: ${(props) => props.iconSize};
  box-shadow: 0 0 0 ${borderSize} ${borderColor};
  animation: ${(props) =>
    props.active
      ? css`
          ${alarmAnimation} 1s infinite
        `
      : ""};
  display: flex;
  justify-content: center;
  align-items: center;
  color: wheat;
  margin-left: auto;
  margin-right: auto;
`;
const Wrapper = styled.div`
  left: 41.5%;
  top: 49px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  /* left: 0;
  right: 0; */
  text-align: center;
  /* text-align: center; */
`;
const StyledText = styled.div`
  color: black;
  font-size: 35px;
  font-weight: 600;
`;
const AlarmMessage = styled.div`
  /* height: 30px;
    width: 100px; */
  width: 350px;
  margin-top: 10px;
  background-color: white;
  padding: 5px 15px;
  border: 2px red solid;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

function AnimatedAlarmIcon(props) {
  const project = useProject();

  return (
    <Wrapper>
      {project.name[0].toLowerCase() === "b" && (
        <div>
          <StyledAlarmIcon iconSize={"50px"} active={props.active}>
            <StyledText>!</StyledText>
          </StyledAlarmIcon>
          <AlarmMessage>
            {`Abnormality detected in electromagnetic levels `}
            {`Address: כ' בחשוון, בית אל`}
            {/* <p>Abnormality detected in electromagnetic levels, address: <em>כ' בחשוון, בית אל</em></p> */}
          </AlarmMessage>
        </div>
      )}
    </Wrapper>
  );
}

export default AnimatedAlarmIcon;
