import React, {useEffect, useState} from 'react';
import {OverlayView} from '@react-google-maps/api';
import SectorsDialog from '../Sector/SectorsDialog'
import styled from "styled-components";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors";
import useProject from "../../Hooks/useProject";
import Constants from "../../controller/Constants";
 
// background-image: url("https://flycomm-cover-rest-api-dev-images.s3.eu-west-1.amazonaws.com/target.jpg"); /*    new     */
//     background-position: center;         /*    new     */
//     background-size: cover;            /*    new     */

const MarkerDiv = styled.div`
  &&{
      z-index: 1;
      &.marker .marker-wrapper:before {
        background-color: ${({backgroundColor}) => backgroundColor} !important;
        ${({imageUrl}) => imageUrl &&`
          //  background-color: blue !important;
           background-image: url(${imageUrl}) !important;
           background-position: center !important;
           background-size: contain !important;
           height: 32px;
           width: 32px;
           top: 3px;
           left: 3px;
        `}
      }
      &.marker{
        text-align: start;
      }
      .title{
        opacity: 0;
        transition: opacity 400ms ease-in;
        &:hover{
          opacity: 1;
        }
      }
      &.marker .marker-wrapper .pin .image{
         box-shadow: none;
        &::after{
          border-top-color:${({theme}) => theme.components.marker.color};
        }
      }
      &.marker .marker-wrapper .pin{
        border-color:${({theme}) => theme.components.marker.color};
        &::before{
            background-color:${({theme}) => theme.components.marker.color};
            border-color:${({theme}) => theme.components.marker.color};
        }
      }
  }
`;

const Title = styled.h3`
  color: #fff;
  position:relative;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  text-align: center;
  line-height: 30px;
`;
const MarkerImage = styled.div`
&&{
  &.markerImage{
    width: 20px;
    height: 20px;
  }
}
  /* position:relative; */
  /* top:50%;
  left:50%; */
  
`;

function StaticAntennaMarker({clusterer, project, site}) {
    return <OverlayView
        position={site.location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
        <div style={{position: 'relative'}}>
            <MarkerDiv
                imageUrl={Constants.DEFAULT_STATIC_ANTENNA_MARKER}
                className="marker">
                  <div className="title">{site.displayName}</div>
                <div className="marker-wrapper">
                    {<div className="tag"><i className="fa fa-check"/></div>}
                    <div className="pin">
                        <Title className='image'></Title>
                    </div>
                </div>
            </MarkerDiv>
        </div>
    </OverlayView>;
}


export default (StaticAntennaMarker);